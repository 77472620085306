import './App.css';
import { BrowserRouter as Router, Route , Routes } from 'react-router-dom';
import Home from './pages/Home';
import {Container, Box, Typography} from '@mui/material';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FSL2plus from "./pages/fsl2-plus";
import FSL2 from "./pages/B2C-IE";
import NotFound from "./pages/NotFound";
import thankYou from "./pages/thankyou";
import Insulet from "./pages/insulet";
import HcpPrimaryCareOrder from "./pages/hcp-primary-care-order";
import React, { useState } from 'react';
import {Helmet} from "react-helmet";
import PatientPrimaryCare from "./pages/patient-primary-care";
const theme = createTheme({
  "fontFamily": `"Helvetica", "Arial", sans-serif`,
  palette: {
    primary: {main:'#FFD100'},
    secondary: {main:'#222731'},
    darkBlue:{main:'#001489'}
  },
});

function App() {
  const [pageClass, setPageClass] = useState('');
  return (
        <Router>
          <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Container className="main_container" maxWidth="md" >
              <Helmet>
                <title>Forms Project</title>
              </Helmet>
              <Header/>
              <Box className={'pages_container '+ pageClass} >
                <Routes>
                <Route exact path='/' Component={Home} />
                <Route exact path='/fsl2-plus' Component={FSL2plus} />
                <Route path='/fsl2-plus/direct-patient-orders' Component={FSL2plus} />
                <Route path='/fsl2-plus/starter-clinic-orders' Component={FSL2plus} />
                <Route path='/fsl2-plus/starter-letter-orders' Component={FSL2plus} />
                <Route exact path='/insulet' Component={Insulet} />
                <Route exact path='/patient-primary-care' Component={PatientPrimaryCare} />
                <Route exact path='/hcp-primary-care' Component={HcpPrimaryCareOrder} />
                <Route path='/thankyou' Component={thankYou} />
                <Route path='/fsl2' Component={FSL2} />
                <Route path='/fsl2/direct-patient-orders' Component={FSL2} />
                <Route path='/fsl2/starter-clinic-orders' Component={FSL2} />
                <Route path="*" Component={NotFound} />
                </Routes>
              </Box>
            </Container>
          </LocalizationProvider>
          </ThemeProvider> 
        </Router>  
  );
}

export default App;
