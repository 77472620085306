import './index.css'
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {zodResolver} from '@hookform/resolvers/zod'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import React, {useState, useRef, useEffect} from 'react';
import {Box,  Typography} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import NormalField from '../../components/form-controls/normal-field';
import AddressLookup from "../../components/form-controls/AddressLookup";
import { Controller } from 'react-hook-form';
import clinics from "../../data/clinics";
import {Helmet} from "react-helmet";
import { ReactComponent as CustomCheckedIcon } from '../../components/icons/checkedBox.svg';
import BasicInfo from "../../components/form-controls/basicInfo";
import {useLocation, useNavigate} from "react-router-dom";
import isIEDomain from "../../helpers/domainChecker";
import FSLFooter from "../../components/fsl-footer/footer";
// Define Zod schema for form validation
const schema = z.object({
  hospitalOrClinic:z.string().optional().nullable(),
  trainingSession:z.string().optional().nullable(),
  trainingSessionDate:z.string().optional().nullable(),
  trainingSessionTime:z.string().optional().nullable(),
  referrer:z.string().optional().nullable(),
  firstName: z.string().min(2, { message: 'First name is too short. It must be at least 2 characters.' }).max(50, { message: 'First name is too long. It must be no more than 50 characters.' }),
  lastName: z.string().min(2, { message: 'Last name is too short. It must be at least 2 characters.' }).max(50, { message: 'Last name is too long. It must be no more than 50 characters.' }),
  emailAddress: z.string().email({ message: 'Invalid email address' }),
  phoneNumber: z.string().regex(/^[0-9][0-9]{10,14}$/, { message: 'Invalid telephone number format.' }),
  postCode: z.string(),
  city: z.string().min(2, { message: 'City is too short. It must be at least 2 characters.' }),
  street: z.string().min(2, { message: 'Street is too short. It must be at least 2 characters.' }),
  carerCheckbox: z.boolean().optional().nullable(),
  commercialUseCheckbox: z.boolean().refine((v)=>v===true,{message:"Field Required"}),
  marketingOptinCheckbox: z.boolean().optional().nullable(),
});
function Insulet() {
  const navigate = useNavigate();
  const location = useLocation();
  const {   control, register, handleSubmit, formState: { errors }, setValue, trigger, setError, clearErrors,setFocus } = useForm({ resolver: zodResolver(schema) ,
    defaultValues: {
    hospitalOrClinic: ''
  } });
  useEffect(() => {
    if(isIEDomain)
        navigate('/404')
  }, [location.pathname]);
  const [clinic, setClinic] =  useState('');
  const formRef = useRef(null);
  const [submitted, setSubmitted] =  useState('t');
    const scrollToForm = () => {
        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
  const onSubmit = async (data) => {
    try {
      data.country = "UK";
      data.address1 = data.street;
      delete data.street;
      const postUrl=process.env.REACT_APP_POST_BASE+ 'insulet'
      const response = await fetch(postUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || "there was an error while submitting your form!");
      }else{
        setSubmitted(true)
      }
      console.log('Form data submitted successfully:', data);
    } catch (error) {
      setSubmitted(false)
    }
  };
  const clinicChange = (value) => {
    console.log(value)
    setClinic(value)
    setValue('hospitalOrClinic', value , { shouldValidate: false });
  }
  const customValidate=() =>{
    let valid=true;
    // clearErrors();
    if(!clinic){
      setError('hospitalOrClinic', { type: 'manual', message: "Field Required" });
      setFocus('hospitalOrClinic');
      valid=false;
    }
    console.log(valid);
    console.log(errors);
    return valid
  }
  return (
      <>
          <Box  className="page-contianer" >
            {submitted!='t' && <div className='page-gray-overlay'></div>}
            <Helmet>
              <title>Insulet</title>
            </Helmet>
            <Box className="upper-section" sx={{paddingBottom:0}}>
              <Box className="upper-section-texts" >
                <Typography className="blue-title">
                    Libre 2 Plus Sensor for Omnipod<sup>®</sup> 5 Automated Insulin Delivery System (AID) Training Order Form
                </Typography>
                <Typography className="secondary-text">
                    Your Healthcare Professional has invited you to attend training for the Omnipod<sup>®</sup> 5 automated insulin delivery system. During the training session you will be asked to start a new Libre 2 Plus sensor.
                </Typography>
              </Box>
              <Box className="right-box" >
                  <img src={process.env.PUBLIC_URL + '/sensor-circle.png'} alt='sensor image' />
                  <Button className="rounded-btn text-white" variant="contained" color="darkBlue" sx={{paddingX:"32px",paddingY:"12px"}} onClick={scrollToForm} >GO TO ORDER FORM</Button>
              </Box>
            </Box>
              <Box className="upper-section-texts" >
                  <Typography className="secondary-text">
                      By placing this order, you will receive two sensors. One is intended for initial use with Omnipod<sup>®</sup> 5 AID, while the second serves as a backup in case it is needed.
                  </Typography>
                  <Typography className="main-text">
                      Important:
                  </Typography>
                  <Typography className="secondary-text">
                      <ol>
                          <li>Delivery of the Libre 2 Plus sensors can take up to 7 days to arrive, so place your order at your earliest convenience.</li>
                          <li>Do not apply the sensor prior to the training, you will need to take it with you to your training.</li>
                      </ol>
                  </Typography>
                  <Typography className="secondary-text">
                      The Libre 2 Plus sensor:
                  </Typography>
                  <Typography className="secondary-text">
                      <ol>
                          <li>Easy to use and comfortable to wear for up to 15 days<sup>1</sup>.</li>
                          <li>Outstanding 15-day accuracy, including strong low-end performance<sup>2</sup>.</li>
                          <li>Intentional partnerships with the OmniPod<sup>®</sup> 5 automated insulin delivery system<sup>3</sup>.</li>
                      </ol>
                  </Typography>
                  <Typography className="secondary-text">
                      <span className='bold'>Please note</span> that as part of our instructions for sensor application you will require alcohol wipes. Alcohol wipes are not provided in the sensor kit but are readily available at minimal cost from pharmacies and other retailers.
                  </Typography>
                  <Typography className="secondary-text">
                      Please follow the instructions below to order your free of charge Libre 2 sensor kits (each contains one Libre 2 Plus sensor pack, one sensor applicator, product insert) for the training session.
                  </Typography>
              </Box>
            <Typography className="main-title" ref={formRef}>
              Order your Libre 2 sensor kit here
            </Typography>
            <form className="form-container" onSubmit={handleSubmit(onSubmit)}>

              <Typography className="main-title-black">
                Personal Details
              </Typography>
              <BasicInfo register={register} errors={errors} setValue={setValue} />
              <Typography className="main-title-black">
                Address Details
              </Typography>
              <Box className="form-group">
                <AddressLookup register={register} errors={errors} setValue={setValue} />
              </Box>
            <Box className="form-group">
               <> <Box className="form-group" sx={{margin:0 , gap:0}}>
               <FormLabel className="radio-label" sx={{ transform: 'none' }} >Hospital/Diabetes Clinic *
                  <Typography className='download-text-small' sx={{ color: "black" }}>
                      Please enter the details of the hospital or diabetes clinic that has requested you order a free of charge Libre 2 Plus sensor kit.
                  </Typography>
                </FormLabel>
                  <Controller
                    name="hospitalOrClinic"
                    control={control}
                    render={({ field ,fieldState}) => (
                      <Autocomplete
                        disablePortal
                        id="clinics-field"
                        options={clinics}
                        sx={{ width: '100%' }}
                        onChange={(event, newValue) => {
                          clinicChange(newValue)
                        }}
                        value={clinic}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={errors.hospitalOrClinic?true:false}
                            // label="Hospital/Diabetes Clinic *"
                            placeholder="Enter Hospital/Diabetes Clinic"
                            InputLabelProps={{ shrink: true, sx: { top: '-18px', left: '-13px' }, disableAnimation: true }}
                            sx={{ width: '100%', marginTop: '8px !important', '& legend': { display: 'none' } }}
                          />
                        )}
                      />
                    )}
                  />

                </Box>
                {errors.hospitalOrClinic && <FormLabel className="validation-error" ><img src='/warningIcon.svg' alt='error'/> {errors.hospitalOrClinic.message}</FormLabel>} </>

                </Box>
                <Box className="form-group" >
                  <FormControlLabel sx={{alignItems:'flex-start','& .MuiCheckbox-root':{paddingTop:0}}} control={<Checkbox {...register('carerCheckbox')} checkedIcon={<CustomCheckedIcon />} />}
                  label="I am completing the form on behalf of someone who suffers from Diabetes Mellitus and who is over the age of 4." />
                  <FormControlLabel error={errors.commercialUseCheckbox?true:false} sx={{alignItems:'flex-start','& .MuiCheckbox-root':{paddingTop:0}}} control={<Checkbox {...register('commercialUseCheckbox')} checkedIcon={<CustomCheckedIcon />} />} label="I agree that the free sample should not be sold or exchanged but is given to me for my personal use.*" />
              <FormControlLabel sx={{ alignItems: 'flex-start', '& .MuiCheckbox-root': { paddingTop: 0 } }} control={<Checkbox {...register('marketingOptinCheckbox')} checkedIcon={<CustomCheckedIcon />} />} label={
                <Typography>
                  Sign-up for free to get access to our educational hub and email communications.
                  Access up-to-date information, education, and training resources surrounding
                  the FreeStyle Libre portfolio and living with diabetes. By checking this box, you
                  agree to receive information on our products and services as explained in{' '}
                  <a href="https://www.freestyle.abbott/uk-en/legal/privacy-policy.html" target="_blank" rel="noopener noreferrer">
                    Abbott’s Privacy Policy
                  </a>.
                </Typography>
              } />
                </Box>
                <Box className="horizontal-form submit-buttons">
              {(submitted !== true && submitted !== false) &&
                <Button className="rounded-btn" sx={{ paddingX: "32px", paddingY: "12px", color: '#000' }} type="submit"  onClick={async (e) => {
                  e.preventDefault();
                  const formValid = await trigger();
                  const customValid = customValidate();
                  if (customValid && formValid) {
                    handleSubmit(onSubmit)();
                  }
                }} variant="contained">REQUEST FREE SAMPLE</Button>}
              {(submitted === true) &&
                <>
                  <Button className="rounded-btn rounded-btn-success" sx={{ paddingX: "32px", paddingY: "12px", color: '#000 !important'}} type="submit" variant="contained" disabled>COMPLETED</Button>
                  <div className='rounded-btn-success-message'>
                  Thank you for requesting the FreeStyle Libre 2 system. To complete your request, please confirm your email address by clicking the email we’ve sent to you.
                  </div>
                </>
              }
              {(submitted === false) &&
                <>
                  <Button className="rounded-btn rounded-btn-failed" sx={{ paddingX: "32px", paddingY: "12px", color: '#fff' }} disabled variant="contained">ERROR</Button>
                  <div className='rounded-btn-failed-message'>
                  Sorry, you have either already registered your details with us or there is issue with one or more of your answers
                  </div>
                </>}
                </Box>
          </form>
          <Box className="form-group" sx={{marginBottom:0}}>
              <Typography sx={{fontSize:'10px',letterSpacing:'0.5px',lineHeight:'120%'}}>
                <div className='ordered-list'>
                <div>1. Haak, T. Diabetes Therapy (2017): https://doi.org/10.1007/s13300-016-0223-6.</div>
                <div>2. Data on file, Abbott Diabetes Care, Inc.</div>
                <div>3. Note that users should start their FreeStyle Libre 2 Plus sensor with OmniPod 5 App.</div>
                </div>
                The personal information you have provided is necessary for Abbott and its suppliers to use to process your order, to confirm your order, to deliver your requested products, and provide after sales service. For further information please see our <a target="_blank" href='https://freestylediabetes.co.uk/privacy-policy'>Privacy Policy</a>.
                <br></br><br></br>
                  ADC-88062 v3.0 08/24
              </Typography>
            </Box>
        </Box>
        <FSLFooter />
      </>
    );
}

export default Insulet;
