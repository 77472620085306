import * as React from 'react';
import { Unstable_NumberInput as BaseNumberInput } from '@mui/base/Unstable_NumberInput';
import { styled } from '@mui/system';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import FormLabel from "@mui/material/FormLabel";

const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
    return (
        <BaseNumberInput
            slots={{
                root: StyledInputRoot,
                input: StyledInput,
                incrementButton: StyledButton,
                decrementButton: StyledButton,
            }}
            slotProps={{
                input:{...props},
                incrementButton: {
                    children: <AddIcon fontSize="small" />,
                    className: 'increment',
                    type:'button'
                },
                decrementButton: {
                    children: <RemoveIcon fontSize="small" />,
                    type:'button'
                },
            }}
            {...props}
            ref={ref}
        />
    );
});

export default function NumberField({ id , label, placeholder, register, errors, className,max ,min }) {
    return (
        <>
            <NumberInput
                id={id}
                {...register}
                aria-label={label}
                placeholder={placeholder}
                error={!!errors}
                className={className}
                min={min==0?min:1} max={max}
            />
            {errors && errors[id] && <FormLabel className="validation-error" ><img src='/warningIcon.svg' alt='error'/> {errors[id].message}</FormLabel>}
        </>
    );
}



const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const StyledInputRoot = styled('div')(
    ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 200px;
  border: 1px solid #363431;
  border-radius:10px;
  height:52px;
  justify-content:space-between;
  
  &.base--error{
  border:2px solid red;
  }
`,
);

const StyledInput = styled('input')(
    ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 0;
  border-radius: 8px;
  margin: 0 8px;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 120px;
  text-align: center;

  &:hover {
    border-color: ${theme.palette.primary[400]};
  }

  &:focus {
    border-color: ${theme.palette.primary[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? theme.palette.primary[700] : theme.palette.primary[200]};
  }

  &:focus-visible {
    outline: 0;
  }
`,
);

const StyledButton = styled('button')(
    ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 2rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 0;
  border-radius:10px 0 0 10px;
  border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  background: #fff;
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  width: 52px;
  height: 52px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
    background: ${theme.palette.primary.main};
    border-color: ${theme.palette.primary.main};
    color: ${grey[50]};
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
    border-radius:0 10px 10px 0 ;
  }
`,
);
