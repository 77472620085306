import './index.css'
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {zodResolver} from '@hookform/resolvers/zod'
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import React, {useState, useRef, useEffect} from 'react';
import {Box, Typography} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import NormalField from '../../components/form-controls/normal-field';
import AddressLookup from "../../components/form-controls/AddressLookup";
import {toast,ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import FormLabel from "@mui/material/FormLabel";
import NumberField from "../../components/form-controls/number-field";
import BasicInfo from "../../components/form-controls/basicInfo";
import {Link, useLocation, useNavigate} from "react-router-dom";
import isIEDomain from "../../helpers/domainChecker";
import HcpFooter from "../../components/hcp-footer/footer";
// Define Zod schema for form validation
let numberValidation = (field,message)=>{
  return z.string().superRefine((val,ctx) => {
    let n = Number(val);
    if(!isNaN(n) && val?.length > 0 && n>=1 && n<=10){
      return true;
    }else{
      ctx.addIssue({ code: z.ZodIssueCode.custom,
        message: message,
        path: [field],
      });
    }
  });
};
const schema = z.object({
  firstName: z.string().min(2, { message: 'First name is too short. It must be at least 2 characters.' }).max(50, { message: 'First name is too long. It must be no more than 50 characters.' }),
  address1: z.string().min(2, { message: 'Please provide a valid surgery name.' }).max(50, { message: 'Surgery name is too long. It must be no more than 50 characters.' }),
  lastName: z.string().min(2, { message: 'Last name is too short. It must be at least 2 characters.' }).max(50, { message: 'Last name is too long. It must be no more than 50 characters.' }),
  emailAddress: z.string().email({ message: 'Invalid email address' }).refine((email)=>
          isAllowEmail(email)
      ,{message:'Not Allowed Email'}),
  phoneNumber: z.string().regex(/^[0-9][0-9]{10,14}$/, { message: 'Invalid telephone number format.' }),
  postCode: z.string(),
  city: z.string(),
  street: z.string(),
  marketingOptinCheckbox: z.boolean().optional().nullable(),
  commercialUseReadersCheckbox: z.boolean().refine((v)=>v===true,{message:"Field Required"}),
  commercialUseSensorsCheckbox: z.boolean().refine((v)=>v===true,{message:"Field Required"}),
  numberSensors: numberValidation("numberSensors","Number of sensors required."),
  numberReaders: z.string().optional().nullable(),
});

function emailHazAllowDomains(email, allowDomains) {
  const emailParts = email.toLowerCase().split('@');
  const domain = emailParts[1];

  // Filter the domain
  const matchedDomains = allowDomains.filter(allowDomain => domain.match(allowDomain));
  // Its valid if there is matched domain
  return matchedDomains.length > 0;
}

function isAllowEmail(email) {
  const allowDomains = [
    'nhs.uk',
    'nhs.net',
    'nhs.scot',
    '24sq.com',
    'abbott.com',
    /\.ie$/, // Anything end with .ie
    /\.hscni.net$/, // Allow subdomains for .hscni.net
    'nobles.dhss.gov.im',
    'gov.gg',
    'diabetes.org.uk',
    'thelondonclinic.co.uk',
    'thewellnessjunction.com',
    'bramacare.co.uk',
    'livahealthcare.com',
    'fpctramore.com',
    'livahealth.com',
    'hscni.net',
    'gov.gg',
    'health.gov.je',
    'locala.org',
    'greensleeves.org.uk',
    "porthosp.nhs.uk",
    "uhs.nhs.uk",
    "southernhealth.nhs.uk",
    "hhft.nhs.uk",
    "solent.nhs.uk",
    "northerntrust.hscni.net",
    "nhs.scot",
    "ggc.scot.nhs.uk",
    "lanarkshire.scot.nhs.uk",
    "nhslothian.scot.nhs.uk",
    "nhsforthvalley.scot.nhs.uk",
    "borders.scot.nhs.uk",
    "aapct.scot.nhs.uk",
    "northerntrust.hscni.net",
    "belfasttrust.hscni.net",
    "westertrust.hscni.net",
    "southerntrust.hscni.net",
    "ncic.nhs.uk",
    "northumbria-healthcare.nhs.uk",
    "nhct.nhs.uk",
    "nhs.net",
    "wales.nhs.uk",
    "oxfordhealth.nhs.uk",
    "royalberkshire.nhs.uk",
    "berkshire.nhs.net",
    "ouh.nhs.uk",
    "uhd.nhs.uk",
    "somersetft.nhs.uk",
    "dorsetgp.nhs.uk",
    "wsh.nhs.uk",
  ];
  return emailHazAllowDomains(email, allowDomains);
}

function PatientPrimaryCare() {
  const navigate = useNavigate();
  const location = useLocation();
  const {  register, handleSubmit, formState: { errors }, setValue, trigger, setError, clearErrors,setFocus } = useForm({ resolver: zodResolver(schema) ,
    defaultValues: {
    hospitalOrClinic: ''
  } });
  useEffect(() => {
    if(isIEDomain)
      navigate('/404')
  }, [location.pathname]);
  const [submitted, setSubmitted] =  useState('t');
  const [collapse_create_account, setCollapseCreateAccount] = useState(false);
  const myRef = useRef(null);
  const scrollToRef = () => {
      if (myRef.current) {
          myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
  };
  const toggleAccordion = () => {
    setCollapseCreateAccount(prevState => !prevState);
  };
  const onSubmit = async (data) => {
    try {
      clearErrors();
      data.country = "UK";
      data["address2"]=data.street;
      delete data.street;
      console.log(data);
      const postUrl=process.env.REACT_APP_POST_BASE+ 'patient-primary-care'
      const response = await fetch(postUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || "there was an error while submitting your form!");
      }else{
        // toast.success("Operation Done Successfully")
        setSubmitted(true)
      }
      console.log('Form data submitted successfully:', data);
    } catch (error) {
      toast.error(error.message,{theme:"dark"});
      setSubmitted(false)
    }
  };

  return (
      <>
          <Box  className="page-contianer" >
            {submitted!='t' && <div className='page-gray-overlay'></div>}
            <Helmet>
              <title>Patient Primary Care</title>
            </Helmet>
            <Box className="upper-section">
          <Box className="upper-section-texts" >
            <Typography className="blue-title">
              Healthcare Professional Order Form for Patient Onboarding
              <div className="font-20" style={{ lineHeight: 1.3 }}>Request free of charge Libre 2 Plus sensor kits for your patients</div>
            </Typography>
            <Typography className="secondary-text">
              Each Libre 2 Plus sensor kit includes:
            </Typography>
            <Typography className="secondary-text">
              <ul>
                <li>One Libre 2 Plus sensor pack</li>
                <li>One sensor applicator</li>
                <li>An insert that guides you through application and set up</li>
              </ul>
            </Typography>
            <Typography className="secondary-text">
              Please note: The Libre 2 Plus sample sensors can take up to 7 days to arrive.
            </Typography>
          </Box>
              <Box className="right-box" >
                <img src={process.env.PUBLIC_URL + '/sensor-circle.png'} />
                <Button className="rounded-btn text-white" variant="contained" color="darkBlue" sx={{paddingX:"32px",paddingY:"12px"}} onClick={scrollToRef} >GO TO ORDER FORM</Button>
              </Box>
            </Box>
            <Typography className="secondary-text">
              If you want to order a Libre 2 Plus sensor for yourself to familiarise yourself with the system, please use the <Link target="_blank" to="/hcp-primary-care" >Healthcare Professional Order Form</Link>.
            </Typography>
            <Box className="organg-panel">
              <Typography className="main-text">
              Your patient will need to download the LibreLink app<sup>◊</sup> to use the sensor.
              </Typography>
              <Box sx={{ width: '100%' }}>
                <Typography className="secondary-text">
                  <a target="_blank" href="https://www.diabetescare.abbott/support/manuals/uk.html" className="blue-bold" >Check smartphone compatibility</a>
                </Typography>
              </Box>
              <Box sx={{ width: '100%' }}>
                <Typography className="secondary-text">
                  <a target="_blank" href="https://pro.freestyle.abbott/uk-en/freestyle-portfolio/digital-health-solutions/freestyle-libre-link.html" className="blue-bold" >Download FreeStyle LibreLink</a>
                </Typography>
              </Box>
              <Typography className="main-text">
                If your patient does not have a compatible device, please request a Libre 2 reader as an alternative to the FreeStyle LibreLink app when filling out the order form below.
              </Typography>
            </Box>
            <Typography className="main-title" ref={myRef}>
              2. Order your Libre 2 Plus Sensor
            </Typography>
            <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
              <Typography className="main-title-black">
                Your Details
              </Typography>
              <BasicInfo register={register} errors={errors} setValue={setValue} />
              <Typography className="main-title-black mb-24">
                Surgery Details
              </Typography>
              <Typography className="secondary-light-text" sx={{margin:'-20px 0 15px 0;'}}>Sensor kits must be sent to the surgery or practice where you work.</Typography>
              <Box className="form-group">
              <NormalField
                label="Surgery name *"
                placeholder="Surgery name"
                register={register('address1')}
                errors={errors.address1}
              />
                <AddressLookup register={register} errors={errors} setValue={setValue} />
              </Box>
              <Typography className="main-title-black">
                Number of Libre 2 Plus sensor samples needed
              </Typography>
              <Typography className="secondary-text">
                If you would like to order more sample sensors than you're able to order using this form, please contact your local Abbott's diabetes care division representative. If you don't know who your local rep is, please get in touch via our <a target="_blank" href="https://pro.freestyle.abbott/uk-en/libre-hub/contact-support.html" >Primary Care contact form</a>.
              </Typography>
              <Box className="form-group"  sx={{marginTop:'24px'}} >
                <NumberField id="numberSensors" register={register('numberSensors')} label="Number of Sensors" placeholder="Sensor" max={10} errors={errors.numberSensors} />
              </Box>
              <Typography className="secondary-text">
                If any of your patients do not have <a target="_blank" href='https://www.diabetescare.abbott/support/manuals/uk.html'>compatible smartphones</a>, you'll need to order Libre 2 readers for them.
              </Typography>
              <Box className="form-group" sx={{marginTop:'24px'}} >
                <NumberField id="numberReaders" register={register('numberReaders')} label="Number of Readers" placeholder="Readers" min={0}  max={5} />
              </Box>
              <Box className="form-group" >
                <FormControlLabel sx={{alignItems:'flex-start','& .MuiCheckbox-root':{paddingTop:0}}} control={<Checkbox {...register('marketingOptinCheckbox')} />} label={
                  <Typography>
                    Register for free to get access to our educational hub and email communication. You'll get unlimited access to up-to-date information, education and training resources surrounding the FreeStyle Libre portfolio and living with diabetes.
                    By checking this box you agree to receive information on our products and services as explained in{' '}
                    <a target="_blank" href="https://pro.freestyle.abbott/uk-en/privacy-policy.html" target="_blank" rel="noopener noreferrer">
                      Abbott’s Privacy Policy
                    </a>.
                  </Typography>
                  }
                />
                <FormControlLabel error={errors.commercialUseReadersCheckbox?true:false} sx={{alignItems:'flex-start','& .MuiCheckbox-root':{paddingTop:0}}} control={<Checkbox {...register('commercialUseReadersCheckbox')}/>} label="I agree that the devices requested should not be sold or exchanged but are given to me for use with my patients. *" />
                <FormControlLabel error={errors.commercialUseSensorsCheckbox?true:false} sx={{alignItems:'flex-start','& .MuiCheckbox-root':{paddingTop:0}}} control={<Checkbox {...register('commercialUseSensorsCheckbox')} />} label="I agree that the requested sample sensors should not be sold or exchanged and is only for me to use with my patients. *" />
              </Box>
              <Box className="horizontal-form submit-buttons">
                <ToastContainer position="bottom-center"
                                autoClose={5000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                />
            {(submitted !== true && submitted !== false) &&
              <Button className="rounded-btn" sx={{ paddingX: "32px", paddingY: "12px", color: '#000' }} type="submit" variant="contained">REQUEST FREE SAMPLE</Button>}
            {(submitted === true) &&
              <>
                <Button className="rounded-btn rounded-btn-success" sx={{ paddingX: "32px", paddingY: "12px", color: '#000' }} type="submit" variant="contained" disabled>COMPLETED</Button>
                <div className='rounded-btn-success-message'>
                  Thank you for requesting the Libre 2 Plus sensors. You can expect to receive the sensors in 7 working days.
                  <br></br>
                  <a target="_blank" href="https://pro.freestyle.abbott/uk-en/home.html" className="blue-bold" >Head to our website</a> to familiarize yourself with the FreeStyle Libre 2 system.
                </div>
              </>
            }
            {(submitted === false) &&
              <>
                <Button className="rounded-btn rounded-btn-failed" sx={{ paddingX: "32px", paddingY: "12px", color: '#000' }} disabled variant="contained">ERROR</Button>
                <div className='rounded-btn-failed-message'>
                Sorry, you have either already registered your details with us or there is issue with one or more of your answers
                </div>
              </>}
              </Box>
          </form>
            <Box className="form-group above-others" sx={{marginBottom:0}}>
            <Typography sx={{fontSize:'10px',letterSpacing:'0.5px',lineHeight:'120%'}}>
                <div className='ordered-list'>
                <div>
                <br></br>◊ The FreeStyle LibreLink app is only compatible with certain mobile devices and operating systems. Please check the website for more information about device compatibility before using the app. Use of FreeStyle LibreLink may require registration with LibreView.
                </div>
                </div>
                <div style={{width:"100%", textAlign:"right"}}>ADC-86656 v2.0 08/24</div>
              </Typography>
            </Box>
        </Box>
        <HcpFooter/>
      </>
    );
}

export default PatientPrimaryCare;
