import { Box, Typography } from '@mui/material'
import isIEDomain from '../../helpers/domainChecker'
import './index.css'
function Header() {
  const handleBack = () =>{
    //navigate(-1);
      if(window.location.pathname =='/hcp-primary-care' || window.location.pathname =='/patient-primary-care' ){
          window.location.href = 'https://pro.freestyle.abbott/uk-en/home.html';
          return '';
      }
      window.location.href = isIEDomain?'https://www.freestyle.abbott/ie-en/home.html':'https://www.freestyle.abbott/uk-en/home.html';
  }
    return (
      <Box className="header-container" >
        <Box className="logos ps-48" >
          <img src={process.env.PUBLIC_URL + (window.location.pathname.indexOf('/fsl2/')==-1?'/main-logo-new.png':'/main-logo.png')} className='main-logo-container' style={{height:'49px'}} alt={"FreeStyle Logo"} />
          <Box sx={{ display: { xs: 'none', sm: 'block' } }} className="abott-logo-container" >
              <img src={process.env.PUBLIC_URL + '/abbott.svg'} alt={"Abbot Logo"} />
          </Box>
        </Box>
        <Box className="yellow-banner ps-48" >
          <Typography onClick={handleBack} sx={{cursor:"pointer"}}>
            ❮ Back to Home
          </Typography>
        </Box>
      </Box>
    );
  }
  
  export default Header;
