import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
	const navigate = useNavigate();

	const handleBackToHome = () => {
		navigate('/');
	};

	return (
		<Container style={{ textAlign: 'center', marginTop: '50px' }}>
			<Box sx={{ my: 4 }}>
				<Typography variant="h1" component="h1">
					404
				</Typography>
				<Typography variant="h4" component="h2">
					Page Not Found
				</Typography>
				<Typography variant="body1" component="p" sx={{ my: 2 }}>
					Sorry, the page you are looking for does not exist.<br/><br/><br/><br/>
				</Typography>

			</Box>
		</Container>
	);
};

export default NotFound;
