import './index.css'
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {zodResolver} from '@hookform/resolvers/zod'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import React, { useEffect, useState , useRef} from 'react';
import {Box, Typography} from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import StyledRadio from '../../components/form-controls/styled-radio'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Slider from '../../components/slider/slider'
import NormalField from '../../components/form-controls/normal-field';
import AddressLookup from "../../components/form-controls/AddressLookup";
import { Controller } from 'react-hook-form';
import clinics from "../../data/clinics";
import {useLocation, useNavigate} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { ReactComponent as CustomCheckedIcon } from '../../components/icons/checkedBox.svg';
import { ReactComponent as CustomCalendarIcon } from '../../components/icons/Calendar-Empty.svg';
import BasicInfo from "../../components/form-controls/basicInfo";
import FSLFooter from "../../components/fsl-footer/footer";
import isIEDomain from "../../helpers/domainChecker";
// Define Zod schema for form validation
const schema = z.object({
  librelinkPhone: z.string().optional().nullable(),
  ownReader: z.string().optional().nullable(),
  hospitalOrClinic:z.string().optional().nullable(),
  trainingSession:z.string().optional().nullable(),
  trainingSessionDate:z.string().optional().nullable(),
  trainingSessionTime:z.string().optional().nullable(),
  referrer:z.string().optional().nullable(),
  firstName: z.string().min(2, { message: 'First name is too short. It must be at least 2 characters.' }).max(50, { message: 'First name is too long. It must be no more than 50 characters.' }),
  lastName: z.string().min(2, { message: 'Last name is too short. It must be at least 2 characters.' }).max(50, { message: 'Last name is too long. It must be no more than 50 characters.' }),
  emailAddress: z.string().email({ message: 'Invalid email address' }),
  phoneNumber: z.string().regex(/^[0-9][0-9]{10,14}$/, { message: 'Invalid telephone number format.' }),
  postCode: z.string().min(5, { message: 'Postcode is too short. It must be at least 5 characters.' }).max(10, { message: 'Postcode is too long. It must be no more than 10 characters.' }),
  city: z.string().min(2, { message: 'City is too short. It must be at least 2 characters.' }),
  street: z.string().min(2, { message: 'Street is too short. It must be at least 2 characters.' }),
  carerCheckbox: z.boolean().optional().nullable(),
  commercialUseCheckbox: z.boolean().refine((v)=>v===true,{message:"Field Required"}),
  marketingOptinCheckbox: z.boolean().optional().nullable(),
  trainingOption: z.enum(["patient","trainingSession","sensorRequest"],{message:"Field Required"}),
});
function FSL2plus() {
  const navigate = useNavigate();
  const {   control, register, handleSubmit, formState: { errors }, setValue, trigger, setError, clearErrors,setFocus } = useForm({ resolver: zodResolver(schema) ,
    defaultValues: {
    hospitalOrClinic: ''
  } });
  const location = useLocation();
  useEffect(() => {
    console.log(isIEDomain);
    if(isIEDomain)
      navigate('/404')
    console.log(location.pathname)
    if (location.pathname === '/fsl2-plus/direct-patient-orders') {
      setValue('trainingOption', 'patient');
      setTrainingOption('patient')
      setTrainingSessionIsActive(false);
      setSensorRequestIsActive(false);
      setAPISUFFIX('direct-patient-orders')
    } else if (location.pathname === '/fsl2-plus/starter-clinic-orders') {
      setValue('trainingOption', 'trainingSession');
      setTrainingOption('trainingSession');
      setSensorRequestIsActive(false);
      setPatientIsActive(false);
      setAPISUFFIX('trainingSession')
    } else if (location.pathname === '/fsl2-plus/starter-letter-orders') {
      setValue('trainingOption', 'sensorRequest');
      setTrainingOption('sensorRequest');
      setTrainingSessionIsActive(false);
      setPatientIsActive(false);
      setAPISUFFIX('starter-letter-orders')
    }
  }, [location.pathname, setValue]);
  const updateAPISuffix = (option) => {
    if (option === 'patient') {
      setAPISUFFIX('direct-patient-orders')
    } else if (option === 'starter-clinic-orders') {
      setAPISUFFIX('starter-clinic-orders')
    } else if (option === 'sensorRequest') {
      setAPISUFFIX('starter-letter-orders')
    }
  };
  const [librelinkPhone, setLibrelinkPhone] =  useState('');
  const [ownReader, setOwnReader] =  useState('');
  const [clinic, setClinic] =  useState('');
  const [referrer, setReferrer] =  useState('');
  const [trainingSession, setTrainingSession] =  useState('');
  const [trainingSessionDate, setTrainingSessionDate] =  useState('');
  const [trainingSessionTime, setTrainingSessionTime] =  useState('');
  const [submitted, setSubmitted] =  useState('t');
  const [trainingOption, setTrainingOption] =  useState('');
  const [APISUFFIX, setAPISUFFIX] =  useState('');
  const [collapse_create_account, setCollapseCreateAccount] = useState(false);
  const formRef = useRef(null);
  const downloadRef = useRef(null);
  const [patientIsActive, setPatientIsActive] =  useState(true);
  const [trainingSessionIsActive, setTrainingSessionIsActive] =  useState(true);
  const [sensorRequestIsActive, setSensorRequestIsActive] =  useState(true);
  const scrollToForm = () => {
      if (formRef.current) {
          formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
  };
  const scrollToDownload = () => {
      if (downloadRef.current) {
          downloadRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
  };
  const toggleAccordion = () => {
    setCollapseCreateAccount(prevState => !prevState);
  };
  const customValidate=() =>{
    let valid=true;
    console.log("test")
    // clearErrors();
    if (!librelinkPhone) {
      setError('librelinkPhone', { type: 'manual', message: "Field Required" });
      setFocus('librelinkPhone');
      valid=false;
    }else if(librelinkPhone==="no"){
       if(!ownReader){
        setError('ownReader', { type: 'manual', message: "Field Required" });
        setFocus('ownReader');
        valid=false;
      }
    }
    if(!trainingOption){
      setError('trainingOption', { type: 'manual', message: "Field Required" });
      setFocus('trainingOption');
      valid=false;
    }else if(trainingOption==="patient"){
      if(!clinic){
        setError('hospitalOrClinic', { type: 'manual', message: "Field Required" });
        setFocus('hospitalOrClinic');
        valid=false;
      }
    }else if(trainingOption==="trainingSession"){
      let temp=false;
      if(!trainingSession){
        setError('trainingSession', { type: 'manual', message: "Field Required" });
        setFocus('trainingSession');
        temp=true;
      }
      if(!trainingSessionDate){
        setError('trainingSessionDate', { type: 'manual', message: "Field Required" });
        setFocus('trainingSessionDate');
        temp=true;
      }
      if(!trainingSessionTime){
        setError('trainingSessionTime', { type: 'manual', message: "Field Required" });
        setFocus('trainingSessionTime');
        temp=true;
      }
      if(temp)valid=false;
    }else if(trainingOption==="sensorRequest"){
          if(!referrer){
            setError('referrer', { type: 'manual', message: "Field Required" });
            setFocus('referrer');
            valid=false;
          }
    }
    console.log(valid);
    console.log(errors);
    return valid
  }
  const onSubmit = async (data) => {
    try {
      data.trainingOption=trainingOption

      data.librelinkPhone=librelinkPhone;
      if(data.librelinkPhone!=='no'){
        delete data.ownReader;
      }else{
        data.ownReader=ownReader
      }
      data.country = "UK";
      data.address1 = data.street;
      delete data.street;
      console.log(data);
      const postUrl=process.env.REACT_APP_POST_BASE+ 'fsl2-plus/' + APISUFFIX
      const response = await fetch(postUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || "there was an error while submitting your form!");
      }else{
        setSubmitted(true)
      }
      console.log('Form data submitted successfully:', data);
    } catch (error) {
      setSubmitted(false)
    }
  };
  const librelinkPhoneChange = async (e) => {
    const val = e.target.value;
      setLibrelinkPhone(val)
  }
  const ownReaderChange = async (e) => {
    const val = e.target.value;
      setOwnReader(val)
  }
  const trainingOptionChange = (e) => {
    console.log(e.target.value)
    clearErrors(['hospitalOrClinic','trainingSession','trainingSessionDate' ,'trainingSessionTime' ,'referrer',]);
    setValue('hospitalOrClinic', '' , { shouldValidate: false })
    setValue('trainingSession', '' , { shouldValidate: false })
    setValue('trainingSessionDate', '' , { shouldValidate: false })
    setValue('trainingSessionTime', '' , { shouldValidate: false })
    setValue('sensorRequest', '' , { shouldValidate: false })
    setClinic('')
    setReferrer('')
    setTrainingSession('')
    setTrainingSessionDate('')
    setTrainingSessionTime('')
    setValue('trainingOption', e.target.value , { shouldValidate: false });
    setTrainingOption(e.target.value)
    updateAPISuffix(e.target.value);
  }
  const clinicChange = (value) => {
    console.log(value)
    setClinic(value)
    setValue('hospitalOrClinic', value , { shouldValidate: false });
  }
  const ReferrerChange = (e) => {
    console.log(e.target.value)
    setReferrer(e.target.value)
    setValue('referrer', e.target.value , { shouldValidate: false });
  }
  const trainingSessionChange= (e) =>{
    console.log(e.target.value)
    setValue('trainingSession', e.target.value || '' , { shouldValidate: false });
    setTrainingSession(e.target.value)
  }  
  const trainingSessionDateChange= (date) =>{
    if (date?._d) {
      const formattedDate = [
        date._d.getFullYear(),
        ('0' + (date._d.getMonth() + 1)).slice(-2),  // months are 0-based
        ('0' + date._d.getDate()).slice(-2)
      ].join('-');
    setValue('trainingSessionDate', formattedDate || '' , { shouldValidate: false });
    setTrainingSessionDate(formattedDate || '')
    }
  }
  const trainingSessionTimeChange= (e) =>{
    if (e._d) {
      const hours = ('0' + e._d.getHours()).slice(-2);
      const minutes = ('0' + e._d.getMinutes()).slice(-2);
      const formattedTime = `${hours}:${minutes}`;
      console.log(formattedTime);
      setValue('trainingSessionTime', formattedTime || '' , { shouldValidate: false });
      setTrainingSessionTime(formattedTime || '')
    }
  }
  return (
      <>
      <Box  className="page-contianer" >
        {submitted !='t' && <div className='page-gray-overlay'></div>}
        <Helmet>
          <title>fsl2-plus</title>
        </Helmet>
        <Box className="upper-section">
          <Box className="upper-section-texts" >
            <Typography className="blue-title">
              The Libre 2 Plus Sensor Order Form
            </Typography>
            <Typography className="main-text">
              You have been invited by your healthcare professional to start using the FreeStyle Libre 2 system to monitor your glucose levels.
            </Typography>
            <Typography className="secondary-text">
              With the Libre 2 system, you can see your real-time glucose levels with a quick glance at your smartphone <sup>⬦</sup><sup>,</sup><sup>¤</sup>  no more finger pricks.
            </Typography>
          </Box>
          <Box className="right-box" >
            <img src={process.env.PUBLIC_URL + '/sensor-circle.png'} alt='sensor image' />
            <Button className="rounded-btn text-white" variant="contained" color="darkBlue" sx={{paddingX:"32px",paddingY:"12px"}} onClick={scrollToForm} >GO TO ORDER FORM</Button>
          </Box>
        </Box>
        <Box className="organg-panel">
          <Typography className="main-title">
            Getting started with the FreeStyle Libre 2 system is easy<sup>2</sup>:
          </Typography>
          <Typography className="secondary-text">
            <a href="javascript:void(0)" className="blue-bold" onClick={scrollToDownload} >1. Download</a> the LibreLink app<sup>◊</sup>
          </Typography>
          <Typography className="secondary-text">
            <a href="javascript:void(0)" className="blue-bold" onClick={scrollToForm} >2. Order</a> your free of charge sensor starter kit using the form below
          </Typography>
        </Box>
        <Box className="main-title "  style={{ maxWidth: '100%' }} ref={downloadRef}>
          1. How to download the LibreLink App<sup>◊</sup>
          <Slider/>
          <Box className="organg-panel organg-with-icon" onClick={toggleAccordion}>
            <div className="icon-container">
              <img src='/3arrows-down.png' alt='arrows down'/>
            </div>
            <div className="organg-with-icon-content">
            <Typography className="main-title secondary-text bold-700 mb-24">
            Once you've downloaded FreeStyle LibreLink, open the app and follow on-screen instructions to get started.
            </Typography>
            <Typography className="secondary-text mb-24">
               During set-up, you can choose to log in or sign up to LibreView.
            </Typography>
            <Typography className="secondary-text">
              <span className={!collapse_create_account ?"blue-bold": "secondary-text bold-700"}>Why create a LibreView account?</span>
            </Typography>
              <div className={collapse_create_account ? 'collapsed-accordion' : 'closed-accordion'}>
                <ul className='unordered-list-accordion secondary-text'>
                  <li>Your glucose readings will be automatically<sup>ǁ</sup> uploaded to LibreView<sup>Δ</sup> — no meter or reader cables required</li>
                  <li>Your personal glucose information is stored on a secure<sup>1</sup>, cloud-based system, and can be shared with your HealthCare Professionals for effective consultations</li>
                  <li>If you change phones, you can sign into the app and your information goes with you</li>
                </ul>
              </div>
            </div>
          </Box>
        </Box>

        <Typography className="main-title" ref={formRef}>
          2. Order your Libre 2 Plus Sensor
        </Typography>
        <div className='secondary-test'>
          Request your free of charge
          Libre 2 Plus sensor kit
          (contains one Libre 2 Plus
          sensor pack, one sensor applicator,
          product insert).<br></br><br></br>
          Please note: The Libre 2
          Plus sensor can take up to 7 days to
          arrive, so order plenty of time in
          advance.
        </div>
        <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
          <Box className="form-group" >
            <Typography  className="danger-txt" sx={{textAlign:'right'}} >
              * Mandatory question
            </Typography>
            <FormControl {...register('librelinkPhone')}>
              <FormLabel error={errors.librelinkPhone?true:false} className="radio-label" >Have you downloaded the FreeStyle LibreLink app on your phone *
              <Typography className='download-text-small' sx={{color:"black"}}>
                Please check your <a target="_blank" href='https://www.diabetescare.abbott/support/manuals/uk.html'>smartphone compatibility</a> and download the app before ordering your sensor.
              </Typography>
              </FormLabel>

              <RadioGroup
                row
                sx={{gap:'32px'}}
                onChange={librelinkPhoneChange}
                value={librelinkPhone}
                name={'librelinkPhone'}
              >
                <FormControlLabel className="choise-label" value={"yes"} control={<StyledRadio />} label="Yes" />
                <FormControlLabel className="choise-label" value={"no"} control={<StyledRadio />} label="No" />
              </RadioGroup>
              {errors.librelinkPhone && <FormLabel className="validation-error" ><img src='/warningIcon.svg' alt='error'/>{errors.librelinkPhone.message}</FormLabel>}
            </FormControl>

            {librelinkPhone === 'no' && <FormControl {...register('ownReader')}>
              <FormLabel error={errors.ownReader?true:false} className="radio-label" >Do you require a Libre 2 reader? *
              <Typography className='download-text-small'>
                If your mobile phone does not support our FreeStyle LibreLink app, then you'll need to select 'Yes' to request a Libre 2 reader.
              </Typography>
              </FormLabel>

              <RadioGroup
                row
                name="ownReader-radio-buttons-group"
                value={ownReader}
                onChange={ownReaderChange}
                sx={{gap:'32px'}}
              >
                <FormControlLabel className="choise-label" value={"no"} control={<StyledRadio />} label="No" />
                <FormControlLabel className="choise-label" value={"yes"} control={<StyledRadio />} label="Yes" />
              </RadioGroup>
              {errors.ownReader && <FormLabel className="validation-error" ><img src='/warningIcon.svg' alt='error'/> {errors.ownReader.message}</FormLabel>}
            </FormControl>
            }
          </Box>
          <Typography className="main-title-black">
            Personal Details
          </Typography>
          <BasicInfo register={register} errors={errors} setValue={setValue} />
          <Typography className="main-title-black">
            Delivery Address
          </Typography>
          <Box className="form-group">
            <AddressLookup register={register} errors={errors} setValue={setValue} />
          </Box>
          <Typography className="main-title-black" >
            Request details
          </Typography>
          <Box className="form-group" > {/*sx={{ marginTop: '24px' }}*/}
            <FormControl {...register('trainingOption')} sx={{ display:'none' }} >
                <FormLabel error={errors.trainingOption?true:false} className="radio-label" >Request type *</FormLabel>
                <Typography className="secondary-light-text" sx={{margin:'-5px 0 5px 15px;'}}>This answer has been pre-populated based on how you accessed this form.</Typography>
                <RadioGroup
                  row
                  name="trainingOption-radio-buttons-group"
                  sx={{gap:'8px'}}
                  onChange={trainingOptionChange}
                  value={trainingOption}

                >
                  <FormControlLabel className="choise-label" value="patient" disabled={!patientIsActive} control={<StyledRadio />} label="Patient order form" />
                  <FormControlLabel className="choise-label" value="trainingSession" disabled={!trainingSessionIsActive} control={<StyledRadio />} label="Online starter clinic training" />
                  <FormControlLabel className="choise-label" value="sensorRequest" disabled={!sensorRequestIsActive} control={<StyledRadio />} label="Sensor request form" />
                </RadioGroup>
              {errors.trainingOption && <FormLabel id="trainingOption-error" className="validation-error"><img src='/warningIcon.svg' alt='error'/> {errors.trainingOption.message}</FormLabel>}
              </FormControl>
            </Box>

        <Box className="form-group">
          {trainingOption === "patient" &&
           <> <Box className="form-group" sx={{margin:0 , gap:0}}>
            <FormLabel className="radio-label" sx={{ transform: 'none' }} >Hospital/Diabetes Clinic *
              <Typography className='download-text-small' sx={{ color: "black" }}>
                Please enter the details of the hospital or diabetes clinic that has requested you order a free of charge Libre 2 Plus sensor kit
              </Typography>
            </FormLabel>
              <Controller
                name="hospitalOrClinic"
                control={control}
                render={({ field ,fieldState}) => (
                  <Autocomplete
                    disablePortal
                    id="clinics-field"
                    options={clinics}
                    sx={{ width: '100%' }}
                    onChange={(event, newValue) => {
                      clinicChange(newValue)
                    }}
                    value={clinic} 
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors.hospitalOrClinic?true:false}
                        // label="Hospital/Diabetes Clinic *"
                        placeholder="Enter Hospital/Diabetes Clinic"
                        InputLabelProps={{ shrink: true, sx: { top: '-18px', left: '-13px' }, disableAnimation: true }}
                        sx={{ width: '100%', marginTop: '8px !important','& legend': { display: 'none' } }}
                      />
                      
                    )}
                  />
                )}
              />
              
            </Box>
            {errors.hospitalOrClinic && <FormLabel className="validation-error" ><img src='/warningIcon.svg' alt='error'/> {errors.hospitalOrClinic.message}</FormLabel>} </>
          }
            {trainingOption === "trainingSession" &&
            <Box className="form-group horizontal-form">
              <Box>
                <NormalField
                    {...register('trainingSession')}
                    label="Training Session *"
                    value={trainingSession}
                    placeholder="Training Session"
                    register={register('trainingSession')}
                    errors={errors.trainingSession}
                    onChange={trainingSessionChange}
                />
              </Box>
              <Box>
              <DatePicker
                  {...register('trainingSessionDate')}
                  views={['year', 'month', 'day']}
                  showDaysOutsideCurrentMonth
                  onChange={trainingSessionDateChange}
                  format="DD-MM-YYYY"
                  slots={{
                    openPickerIcon: CustomCalendarIcon
                  }}
                  slotProps={{
                    openPickerIcon: { fontSize: 'small' },
                    textField: {
                      variant:"outlined",
                      label:"Select Date *",
                      placeholder:"Date",
                      InputLabelProps:{ shrink: true ,sx:{'& .MuiFormLabel-root':{color:'black'},color:'black','top':'-18px','left':'-13px'} , disableAnimation:true },
                      sx:{'& legend':{display:'none'}},
                      error:errors.trainingSessionDate,
                      helperText: errors.trainingSessionDate?.message,
                    },
                    popper:{
                      sx:{
                        "& .MuiPickersDay-root:hover":{ backgroundColor:'#ffea8a' } ,
                        "& .MuiPickersDay-today":{ backgroundColor:'#fff',border:'2px solid #FFD100 !important' },
                        "& .Mui-selected" :{ backgroundColor:"#FFD100 !important",color:'#000',border:'none' }
                      }
                    }
                  }}
              />
              </Box>
              <Box>
              <TimePicker
                {...register('trainingSessionTime')}
                onChange={trainingSessionTimeChange}
                ampm={false}
                slotProps={{
                  openPickerIcon: {fontSize: 'small'},
                  textField: {
                    variant: "outlined",
                    label: "Select Time *",
                    placeholder: "--:--",
                    InputLabelProps: {
                      shrink: true,
                      sx: {'& .MuiFormLabel-root': {color: 'black'}, color: 'black', 'top': '-18px', 'left': '-13px'},
                      disableAnimation: true
                    },
                    sx: {'& legend': {display: 'none'}},
                    error:errors.trainingSessionTime,
                    helperText: errors.trainingSessionTime?.message,
                  },
                }}
              />
              </Box>
            </Box>
            }
            {trainingOption === "sensorRequest" &&
              <>
                <Box className="form-group" sx={{margin:0 , gap:0}}>
                  <FormLabel className="radio-label" sx={{ transform: 'none',marginBottom:"-14px" }} >Name of Referrer *
                    <Typography className='download-text-small' sx={{ color: "black" }}>
                      Please provide the name of the healthcare professional who referred you to this form.
                    </Typography>
                  </FormLabel>
                  <Controller
                      name="referrer"
                      control={control}
                      render={({ field ,fieldState}) => (
                          <NormalField
                              {...register('referrer')}
                              label=""
                              value={referrer}
                              placeholder="Name of referrer"
                              errors={errors.referrer}
                              onChange={ReferrerChange}
                          />
                      )}
                  />
                </Box>
              {/*{errors.referrer && <FormLabel className="validation-error" ><img src='/warningIcon.svg' alt='error'/> {errors.referrer.message}</FormLabel>}*/}
              </>
            }

            </Box>
            <Box className="form-group" >
              <FormControlLabel sx={{alignItems:'flex-start','& .MuiCheckbox-root':{paddingTop:0}}} control={<Checkbox {...register('carerCheckbox')} checkedIcon={<CustomCheckedIcon />} />}
              label="I have Diabetes Mellitus or am completing this form on behalf of someone with Diabetes Mellitus who is over the age of 2" />
              <FormControlLabel error={errors.commercialUseCheckbox?true:false} sx={{alignItems:'flex-start','& .MuiCheckbox-root':{paddingTop:0}}} control={<Checkbox {...register('commercialUseCheckbox')} checkedIcon={<CustomCheckedIcon />} />} label="I agree that this free sample should not be sold or exchanged and is only for use by myself or the person with Diabetes Mellitus that I am requesting this sample on behalf of.*" />
          <FormControlLabel sx={{ alignItems: 'flex-start', '& .MuiCheckbox-root': { paddingTop: 0 } }} control={<Checkbox {...register('marketingOptinCheckbox')} checkedIcon={<CustomCheckedIcon />} />} label={
            <Typography>
              Sign-up for free to get access to our educational hub and email communications.
              Access up-to-date information, education, and training resources surrounding
              the FreeStyle Libre portfolio and living with diabetes. By checking this box, you
              agree to receive information on our products and services as explained in{' '}
              <a target="_blank" href="https://pro.freestyle.abbott/uk-en/privacy-policy.html" target="_blank" rel="noopener noreferrer">
                Abbott’s Privacy Policy
              </a>.
            </Typography>
          } />
            </Box>
            <Box className="horizontal-form submit-buttons">
          {(submitted !== true && submitted !== false) &&
            <Button className="rounded-btn" sx={{ paddingX: "32px", paddingY: "12px", color: '#000' }} type="submit"  onClick={async (e) => {
              e.preventDefault();
              const formValid = await trigger();
              const customValid = customValidate();
              if (customValid && formValid) {
                handleSubmit(onSubmit)();
              }
            }} variant="contained">REQUEST FREE SAMPLE</Button>}
          {(submitted === true) &&
            <>
              <Button className="rounded-btn rounded-btn-success" sx={{ paddingX: "32px", paddingY: "12px", color: '#000 !important'}} type="submit" variant="contained" disabled>COMPLETED</Button>
              <div className='rounded-btn-success-message'>
              Thank you for requesting the FreeStyle Libre 2 system. To complete your request, please confirm your email address by clicking the email we’ve sent to you.
              </div>
            </>
          }
          {(submitted === false) &&
            <>
              <Button className="rounded-btn rounded-btn-failed" sx={{ paddingX: "32px", paddingY: "12px", color: '#fff' }} disabled variant="contained">ERROR</Button>
              <div className='rounded-btn-failed-message'>
              Sorry, you have either already registered your details with us or there is issue with one or more of your answers
              </div>
            </>}
            </Box>
      </form>
        <Box className="form-group above-others" sx={{marginBottom:0}}>
          <Typography sx={{fontSize:'10px',letterSpacing:'0.5px',lineHeight:'120%'}}>
            <div className='ordered-list'>
            <div>1. LibreView is ISO27001/27018/27701 certified and HITRUST CSF Certified.</div>
            <div>2. Data on file, Abbott Diabetes Care, Inc.
            <br></br><sup>ǁ</sup> The user’s device must have internet connectivity for glucose data to automatically upload to LibreView and to transfer to connected LibreLinkUp app users.
            <br></br><sup>¤</sup> Glucose readings are automatically displayed in the app only when the smartphone and sensor are connected and in range.
            <br></br><sup>Δ</sup> The LibreView website is only compatible with certain operating systems and browsers. Please check <a target="_blank" href='https://www.libreview.com/'>www.libreview.com</a> for additional information.
            <br></br><sup>⬦</sup> The FreeStyle LibreLink app is only compatible with certain mobile devices and operating systems. Please check the website for more information about device compatibility before using the app. Use of FreeStyle LibreLink may require registration with LibreView.
            <br></br><sup>†</sup> Finger pricks are required if your glucose readings and alarms do not match symptoms or expectations.
            <br></br><sup>₸</sup> A wireless internet connection or mobile data connection is required to download FreeStyle LibreLink app. Charges may apply.
            The personal information you have provided is necessary for Abbott and its suppliers to use to process your order, to confirm your order, to deliver your requested products, and provide after sales service. For further information please see our <a target="_blank" href='https://freestylediabetes.co.uk/privacy-policy'>Privacy Policy</a>.
            iPhone and App Store are trademarks of Apple Inc.</div>
            </div>
            <div style={{width:"100%", textAlign:"right"}}>ADC-86657 v2.0 08/24</div>
          </Typography>
        </Box>
    </Box>
    <FSLFooter />
  </>
    );
}

export default FSL2plus;
