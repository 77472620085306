import './index.css'
import { useForm } from 'react-hook-form';
import {  z } from 'zod';
import {zodResolver} from '@hookform/resolvers/zod'
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import React, {useState, useRef, useEffect} from 'react';
import {Box,  Typography} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import NormalField from '../../components/form-controls/normal-field';
import AddressLookup from "../../components/form-controls/AddressLookup";
import {Helmet} from "react-helmet";
import BasicInfo from "../../components/form-controls/basicInfo";
import {ReactComponent as CustomCheckedIcon} from "../../components/icons/checkedBox.svg";
import FormLabel from "@mui/material/FormLabel";
import HcpFooter from "../../components/hcp-footer/footer";
import {Link, useLocation, useNavigate} from "react-router-dom";
import isIEDomain from "../../helpers/domainChecker";
// Define Zod schema for form validation
const schema = z.object({
  firstName: z.string().min(2, { message: 'First name is too short. It must be at least 2 characters.' }).max(50, { message: 'First name is too long. It must be no more than 50 characters.' }),
  address1: z.string().min(2, { message: 'Please provide a valid surgery name.' }).max(50, { message: 'Surgery name is too long. It must be no more than 50 characters.' }),
  lastName: z.string().min(2, { message: 'Last name is too short. It must be at least 2 characters.' }).max(50, { message: 'Last name is too long. It must be no more than 50 characters.' }),
  emailAddress: z.string().email({ message: 'Invalid email address' }).refine((email)=>
    isHcpAllowEmail(email)
  ,{message:'Not Allowed Email'}),
  phoneNumber: z.string().regex(/^[0-9][0-9]{10,14}$/, { message: 'Invalid telephone number format.' }),
  postCode: z.string(),
  city: z.string(),
  street: z.string(),
  marketingOptinCheckbox: z.any().optional().nullable(),
  commercialUseSensorsCheckbox: z.boolean().refine((v)=>v===true,{message:"Field Required"}),
});

function emailHazAllowDomains(email, allowDomains) {
  const emailParts = email.toLowerCase().split('@');
  const domain = emailParts[1];

  // Filter the domain
  const matchedDomains = allowDomains.filter(allowDomain => domain.match(allowDomain));
  // Its valid if there is matched domain
  return matchedDomains.length > 0;
}

function isHcpAllowEmail(email) {
  const allowDomains = [
    'nhs.uk',
    'nhs.net',
    'nhs.scot',
    '24sq.com',
    'abbott.com',
    /\.ie$/, // Anything end with .ie
    /\.hscni.net$/, // Allow subdomains for .hscni.net
    'nobles.dhss.gov.im',
    'gov.gg',
    'diabetes.org.uk',
    'thelondonclinic.co.uk',
    'thewellnessjunction.com',
    'bramacare.co.uk',
    'livahealthcare.com',
    'fpctramore.com',
    'livahealth.com',
    'hscni.net',
    'gov.gg',
    'health.gov.je',
    'locala.org',
    'greensleeves.org.uk',
    "porthosp.nhs.uk",
    "uhs.nhs.uk",
    "southernhealth.nhs.uk",
    "hhft.nhs.uk",
    "solent.nhs.uk",
    "northerntrust.hscni.net",
    "nhs.scot",
    "ggc.scot.nhs.uk",
    "lanarkshire.scot.nhs.uk",
    "nhslothian.scot.nhs.uk",
    "nhsforthvalley.scot.nhs.uk",
    "borders.scot.nhs.uk",
    "aapct.scot.nhs.uk",
    "northerntrust.hscni.net",
    "belfasttrust.hscni.net",
    "westertrust.hscni.net",
    "southerntrust.hscni.net",
    "ncic.nhs.uk",
    "northumbria-healthcare.nhs.uk",
    "nhct.nhs.uk",
    "nhs.net",
    "wales.nhs.uk",
    "oxfordhealth.nhs.uk",
    "royalberkshire.nhs.uk",
    "berkshire.nhs.net",
    "ouh.nhs.uk",
    "uhd.nhs.uk",
    "somersetft.nhs.uk",
    "dorsetgp.nhs.uk",
    "wsh.nhs.uk",
  ];
  return emailHazAllowDomains(email, allowDomains);
}
function HcpPrimaryCareOrder() {
  const navigate = useNavigate();
  const location = useLocation();
  if(isIEDomain)
    navigate('/404')
  const {  fieldState, control, register, handleSubmit, formState: { errors }, setValue, trigger, setError, clearErrors,setFocus } = useForm({ resolver: zodResolver(schema) ,
    defaultValues: {
    hospitalOrClinic: ''
  } });
  useEffect(() => {
    if(isIEDomain)
      navigate('/404')
  }, [location.pathname]);
  const formRef = useRef(null);
  const [submitted, setSubmitted] =  useState('t');
  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const onSubmit = async (data) => {
    try {
      data["address2"]=data.street;
      data.country= "UK"
      const postUrl=process.env.REACT_APP_POST_BASE+ 'hcp-primary-care'
      const response = await fetch(postUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || "there was an error while submitting your form!");
      }else{
        setSubmitted(true)
      }
      console.log('Form data submitted successfully:', data);
    } catch (error) {
      setSubmitted(false)
    }
  };
  return (
      <>
      <Box  className="page-contianer" >
        {submitted!='t' && <div className='page-gray-overlay'></div>}
        <Helmet>
          <title>HCP Primary Care</title>
        </Helmet>
        <Box className="upper-section" sx={{paddingBottom:0}}>
          <Box className="upper-section-texts" >
            <Typography className="blue-title">
            Healthcare Professional Order Form
            <div className="font-20" style={{ lineHeight: 1.3 }}>Request a free of charge Libre 2 Plus sensor kit to familiarise yourself with the system</div>
            </Typography>
            <Typography className="secondary-text">
            Each Libre 2 Plus sensor kit includes:
            </Typography>
            <Typography className="secondary-text">
            <ul>
                <li>One Libre 2 Plus sensor pack</li>
                <li>One sensor applicator</li>
                <li>An insert that guides you through application and set up</li>
             </ul>
            </Typography>
            <Typography className="secondary-text">
              Please note: The Libre 2 Plus sensor kit can take up to 7 days to arrive.
            </Typography>
          </Box>
          <Box className="right-box" >
            <img src={process.env.PUBLIC_URL + '/sensor-circle.png'} alt='sensor image' />
            <Button className="rounded-btn text-white" variant="contained" color="darkBlue" sx={{paddingX:"32px",paddingY:"12px"}} onClick={scrollToForm} >GO TO ORDER FORM</Button>
          </Box>
        </Box>
        <Typography className="secondary-text">
          If you need to order Libre 2 Plus sensors for your patients, please use the <Link target="_blank" to="/patient-primary-care" >Patient Order Form</Link>.
        </Typography>
        <Box className="organg-panel">
          <Typography className="main-text">
          You'll need to download the LibreLink app<sup>◊</sup> to use your Libre 2 Plus sensor.
          </Typography>
          <Box sx={{width:'100%'}}>
            <Typography className="secondary-text">
            <a target="_blank" href="https://www.diabetescare.abbott/support/manuals/uk.html" className="blue-bold" >Check smartphone compatibility</a>
          </Typography>
          </Box>
          <Box sx={{width:'100%'}}>
            <Typography className="secondary-text">
            <a target="_blank" href="https://pro.freestyle.abbott/uk-en/freestyle-portfolio/digital-health-solutions/freestyle-libre-link.html" className="blue-bold" >Download FreeStyle LibreLink</a>
          </Typography>
          </Box>
        </Box>
        <Typography className="main-title" ref={formRef}>
          Order your Libre 2 Plus sensor kit
        </Typography>
        <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
          <Box className="form-group" >
            <Typography  className="danger-txt" sx={{textAlign:'right'}} >
              * Mandatory question
            </Typography>
          </Box>
          <Typography className="main-title-black">
            Personal Details
          </Typography>
          <BasicInfo register={register} errors={errors} setValue={setValue} />
          <Typography className="main-title-black mb-24">
            Surgery Details
          </Typography>
          <Typography className="secondary-light-text" sx={{margin:'-20px 0 15px 0px;'}}>Your sample sensor must be sent to the surgery or practice where you work.</Typography>
          <Box className="form-group">
          <NormalField
                label="Surgery name *"
                placeholder="Surgery name"
                register={register('address1')}
                errors={errors.address1}
              />
            <AddressLookup register={register} errors={errors} setValue={setValue} />
          </Box>
            <div className='main-text font-20 mb-24'>
              This form is for healthcare professionals to request a single free of charge Libre 2 Plus sensor to familiarise themselves with the system.
            </div>
            <Box className="form-group" >
              <FormControlLabel sx={{alignItems:'flex-start','& .MuiCheckbox-root':{paddingTop:0}}} control={<Checkbox {...register('marketingOptinCheckbox')} />} checkedIcon={<CustomCheckedIcon />} label={
                <Typography>
                  Register for free to get access to our educational hub and email communication. You'll get unlimited access to up-to-date information, education and training resources surrounding the FreeStyle Libre portfolio and living with diabetes. By checking this box you agree to receive information on our products and services as explained in
                  {' '}
                  <a target="_blank" href="https://pro.freestyle.abbott/uk-en/privacy-policy.html" target="_blank" rel="noopener noreferrer">
                    Abbott's Privacy Policy
                  </a>.
                </Typography>
              } />
              <FormControlLabel error={errors.commercialUseSensorsCheckbox?true:false} sx={{alignItems:'flex-start','& .MuiCheckbox-root':{paddingTop:0}}} control={<Checkbox {...register('commercialUseSensorsCheckbox')}/>} checkedIcon={<CustomCheckedIcon />} label="I agree that the requested sensor kit should not be sold or exchanged and is for my own use.*" />
            </Box>
            <Box className="horizontal-form submit-buttons">
          {(submitted !== true && submitted !== false) &&
            <Button className="rounded-btn" sx={{ paddingX: "32px", paddingY: "12px", color: '#000' }} type="submit" variant="contained">REQUEST FREE SAMPLE</Button>}
          {(submitted === true) &&
            <>
              <Button className="rounded-btn rounded-btn-success" sx={{ paddingX: "32px", paddingY: "12px", color: '#000 !important' }} type="submit" variant="contained" disabled>COMPLETED</Button>
              <div className='rounded-btn-success-message'>
                Thank you for requesting the Libre 2 Plus sensors. You can expect to receive the sensors in 7 working days.
                <br></br>
                <a target="_blank" href="https://pro.freestyle.abbott/uk-en/home.html" className="blue-bold" >Head to our website</a> to familiarize yourself with the FreeStyle Libre 2 system.
              </div>
            </>
          }
          {(submitted === false) &&
            <>
              <Button className="rounded-btn rounded-btn-failed" sx={{ paddingX: "32px", paddingY: "12px", color: '#fff' }} disabled variant="contained">ERROR</Button>
              <div className='rounded-btn-failed-message'>
                Sorry, you have either already registered your details with us or there is issue with one or more of your answers
              </div>
            </>}
        </Box>
      </form>
      <Box className="form-group above-others" sx={{marginBottom:0}}>
          <Typography sx={{fontSize:'10px',letterSpacing:'0.5px',lineHeight:'120%'}}>
            <div className='ordered-list'>
            <div>
            <br></br>◊ The FreeStyle LibreLink app is only compatible with certain mobile devices and operating systems. Please check the website for more information about device compatibility before using the app. Use of FreeStyle LibreLink may require registration with LibreView.
            </div>
            </div>
            <div style={{width:"100%", textAlign:"right"}}>ADC-86658 v2.0 08/24</div>
          </Typography>
        </Box>
    </Box>
        <HcpFooter/>
      </>
    );
}

export default HcpPrimaryCareOrder;
