import React from 'react';
import TextField from '@mui/material/TextField';

const NormalField = ({ id, label, placeholder, register, errors, onChange ,value}) => {
  return (
    <TextField
      id={id}
      value={value}
      {...register}
      label={label}
      placeholder={placeholder}
      variant="outlined"
      helperText={errors ? errors.message==="Required"?'Field Required':errors.message : ''}
      error={!!errors}
      InputLabelProps={{
        shrink: true,
        sx: { top: '-18px', left: '-13px' },
        disableAnimation: true,
      }}
      onChange={onChange}
      sx={{ width: '100%', '& legend': { display: 'none' } }}
    />
  );
};

export default NormalField;
