import './index.css'
import React from 'react';
import isIEDomain from "../../helpers/domainChecker";
import {Typography} from "@mui/material";
import IEFooter from "../../components/ie-footer/footer";
import FSLFooter from "../../components/fsl-footer/footer";
const thankYou = () => {
	return (
		<>
			<div className='row'>
				<div className='column1'>
					<div className='title'>
						Congratulations! You've successfully requested your free Libre 2 Plus sensor kit.
					</div>
					<div className='bold my-24'>
						Now it's over to us to get your order dispatched. You can expect to receive your sensor kit in 7 working days.
					</div>
					<div>
						If you do not receive your order after 7 working days, please email us at <a className='hyperlink' href='mailto:abbott.freestylelibre@nhs.net'>abbott.freestylelibre@nhs.net</a> or give us a call on 0345 607 3247.
					</div>
					<div className='my-24'>
					    When contacting us, please make sure you include your full name and address to help us find your order as quickly as possible.
					</div>
					<div className='bold'>
					    Your FreeStyle Team
					</div>
				</div>
				<div className='column2'>
	                   <img src='./sensor-circle.png' alt="sensor image" />
				</div>
			</div>
			<Typography sx={{fontSize:'10px',letterSpacing:'0.5px',lineHeight:'120%'}}>
				<div style={{width:"100%", textAlign:"right"}}>ADC-86852 v2.0 08/24</div>
			</Typography>
			{ isIEDomain && <IEFooter/>}
			{ !isIEDomain && <FSLFooter />}
		</>
	); 
};

export default thankYou;
