import './index.css'
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
function Home() {
    const navigate = useNavigate();
    const [page,setPage]=useState('')
    function handleClick(page){
     return ()=>{
      setPage(page)
     }
    }
    useEffect(()=>{
      navigate('/404'); // TODO: Hide form live until pushed live
      //navigate(page);
    },[page,navigate])
    return (
      <Box className="test_page">
        <Button variant="contained" onClick={handleClick('/fsl2-plus')}>
          Go to default page
        </Button>
        <Button variant="contained" onClick={handleClick('/fsl2-plus/direct-patient-orders')}>
          Go to direct patient orders
        </Button>
        <Button variant="contained" onClick={handleClick('/fsl2-plus/starter-clinic-orders')}>
          Go to starter clinic orders
        </Button>
        <Button variant="contained" onClick={handleClick('/fsl2-plus/starter-letter-orders')}>
          Go to starter letter order
        </Button>
        <Button variant="contained" onClick={handleClick('/thankyou')}>
          Go to thankyou
        </Button>
        <Button variant="contained" onClick={handleClick('/insulet')}>
          Go to Insulet Page
        </Button>
        <Button variant="contained" onClick={handleClick('/hcp-primary-care')}>
          Go to hcp-primary-care Page
        </Button>
        <Button variant="contained" onClick={handleClick('/patient-primary-care')}>
          Go to Patient Primary Care Page
        </Button>
        <Button variant="contained" onClick={handleClick('/fsl2/direct-patient-orders')}>
          Go to B2C direct patient orders 
        </Button>
        <Button variant="contained" onClick={handleClick('/fsl2/starter-clinic-orders')}>
          Go to starter B2C clinic orders
        </Button>
      </Box>
    );
}
  
export default Home;
