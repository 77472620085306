import React, { useState } from 'react';
import { TextField, Box, Autocomplete } from '@mui/material';
import Button from '@mui/material/Button';
import FormLabel from "@mui/material/FormLabel";
import NormalField from "./normal-field";

const BasicInfo = ({ register, errors, setValue }) => {
	const [firstName, setFirstName] =  useState('');
	const [lastName, setLastName] =  useState('');
	const [emailAddress, setEmailAddress] =  useState('');
	const [phoneNumber, setPhoneNumber] =  useState('');
	const handleFirstNameChanged = (e) => {
		setFirstName(e.target.value)
		setValue('firstName',e.target.value)
	};
	const handleLastNameChanged = (e) => {
		setLastName(e.target.value)
		setValue('lastName',e.target.value)
	};
	const handleEmailAddressChanged = (e) => {
		setEmailAddress(e.target.value)
		setValue('emailAddress',e.target.value)
	};
	const handlePhoneNumberChanged = (e) => {
		setPhoneNumber(e.target.value)
		setValue('phoneNumber',e.target.value)
	};

	return (
		<Box className="form-group" >
			<Box className="form-group horizontal-form" sx={{marginTop:'24px'}}  >
				<NormalField
					label="First name *"
					placeholder="First name"
					{...register('firstName')}
					errors={errors.firstName}
					value={firstName}
					onChange={handleFirstNameChanged}
				/>
				<NormalField
					label="Last name *"
					placeholder="Last name"
					{...register('lastName')}
					errors={errors.lastName}
					value={lastName}
					onChange={handleLastNameChanged}

				/>
			</Box>
			<Box className="form-group horizontal-form" >
				<NormalField
					label="Email address *"
					placeholder="Email address"
					{...register('emailAddress')}
					errors={errors.emailAddress}
					value={emailAddress}
					onChange={handleEmailAddressChanged}
				/>
				<NormalField
					label="Telephone number *"
					placeholder="Telephone number"
					{...register('phoneNumber')}
					errors={errors.phoneNumber}
					value={phoneNumber}
					onChange={handlePhoneNumberChanged}
				/>
			</Box>
		</Box>
	);
};

export default BasicInfo;
