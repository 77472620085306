import {React,useState ,useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules'; // Corrected import for Pagination module
import './ImageSlider.css'; // Custom styles

const images = [
  {
    desktop: '/slider-photos/frame_1.jpg',
    mobile: '/slider-photos/frame_1_mob.jpg'
  },
  {
    desktop: '/slider-photos/frame_2.jpg',
    mobile: '/slider-photos/frame_2_mob.jpg'
  },
  {
    desktop: '/slider-photos/frame_3.jpg',
    mobile: '/slider-photos/frame_3_mob.jpg'
  },
  {
    desktop: '/slider-photos/frame_4.jpg',
    mobile: '/slider-photos/frame_4_mob.jpg'
  },
  {
    desktop: '/slider-photos/frame_5.jpg',
    mobile: '/slider-photos/frame_5_mob.jpg'
  }
];
const ImageSlider = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="slider-container">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
        modules={[Pagination]}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              src={screenWidth > 768 ? image.desktop : image.mobile}
              alt={`Slide ${index}`}
              className="slider-image"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ImageSlider;
