const clinics = [
  "2-4 Waterloo Close",
  "375 Great Western Lodge",
  "Aberdeen Community Health and Care Village",
  "Aberdeen Maternity Hospital",
  "Aberdeen Royal Infirmary",
  "Aberfeldy Cottage Hospital",
  "Aboyne Hospital",
  "Acorn House",
  "Acorn Street Day Hospital",
  "Adamson Hospital",
  "Ailsa Hospital",
  "Airbles Road Centre",
  "Airedale NHS Foundation Trust",
  "Alder Hey Children's NHS Foundation Trust",
  "Andrew Lang Unit",
  "Annan Hospital",
  "Anvil Resource Centre",
  "Arbroath Infirmary",
  "Argyll and Bute Hospital",
  "Armitstead Child Development Centre",
  "Arndale Resource Centre",
  "Arran Resource Centre",
  "Arran War Memorial Hospital",
  "Arrol Park Resource Centre",
  "Ashford and St Peter's Hospitals NHS Foundation Trust",
  "Astley Ainslie Hospital",
  "Auchinlea Resource Centre",
  "Aviemore Hospital (Land)",
  "Avon and Wiltshire Mental Health Partnership NHS Trust",
  "Ayrshire Central Hospital",
  "Ballenden House",
  "Barking, Havering and Redbridge University Hospitals NHS Trust",
  "Barnet, Enfield and Haringey Mental Health NHS Trust",
  "Barnsley Hospital NHS Foundation Trust",
  "Barts Health NHS Trust",
  "Basildon and Thurrock University Hospitals NHS Foundation Trust",
  "Bathgate House Day Hospital",
  "Beatson West of Scotland Cancer Centre",
  "Beckford Lodge",
  "Bedford Hospital NHS Trust",
  "Belford Hospital",
  "Belhaven Hospital",
  "Bellsdyke Hospital",
  "Berkshire Healthcare NHS Foundation Trust",
  "Biggart Hospital",
  "Birmingham Community Healthcare NHS Foundation Trust",
  "Birmingham Women's and Children's NHS Foundation Trust",
  "Birmingham and Solihull Mental Health NHS Foundation Trust",
  "Black Country Partnership NHS Foundation Trust",
  "Blackpool Teaching Hospitals NHS Foundation Trust",
  "Blairgowrie &amp; Rattray Cottage Hospital",
  "Bluebell Intermediate Care Unit",
  "Blythswood House",
  "Bo'ness Hospital",
  "Bolton NHS Foundation Trust",
  "Bonnyrigg Day Centre",
  "Borders General Hospital",
  "Bradford District NHS Foundation Trust",
  "Bradford Teaching Hospitals NHS Foundation Trust",
  "Brechin Infirmary",
  "Bridgeview Resource Centre",
  "Bridgewater Community Healthcare NHS Foundation Trust",
  "Brighton and Sussex University Hospitals NHS Trust",
  "Brooksby Day Hospital",
  "Buckie Day Hospital",
  "Buckinghamshire Healthcare NHS Trust",
  "Bungalows/Red Lodge",
  "CAMHS Hospital Unit",
  "Caithness General Hospital",
  "Calderdale and Huddersfield NHS Foundation Trust",
  "Cambridge Street Day Centre",
  "Cambridge University Hospitals NHS Foundation Trust",
  "Cambridgeshire Community Services NHS Trust",
  "Cambridgeshire and Peterborough NHS Foundation Trust",
  "Camden and Islington NHS Foundation Trust",
  "Cameron Hospital",
  "Campbeltown Hospital",
  "Cardoness",
  "Carseview Centre",
  "Castle Douglas Community Hospital",
  "Central London Community Healthcare NHS Trust",
  "Central and North West London NHS Foundation Trust",
  "Centre for Integrative Care",
  "Chalmers Hospital",
  "Chelsea and Westminster Hospital NHS Foundation Trust",
  "Cheshire and Wirral Partnership NHS Foundation Trust",
  "Chesterfield Royal Hospital NHS Foundation Trust",
  "City Hospital",
  "City Hospitals Sunderland NHS Foundation Trust",
  "Clackmannanshire Community Healthcare Centre",
  "Cleland Hospital",
  "Coathill Hospital",
  "Coathill House",
  "Cornwall Partnership NHS Foundation Trust",
  "Corstorphine Hospital",
  "Countess Of Chester Hospital NHS Foundation Trust",
  "County Community Hospital Invergordon",
  "County Durham and Darlington NHS Foundation Trust",
  "Coventry and Warwickshire Partnership NHS Trust",
  "Cowal Community Hospital",
  "Crichton Royal Hospital",
  "Crieff Community Hospital",
  "Cromarty",
  "Croydon Health Services NHS Trust",
  "Crumhaugh House",
  "Cumbria Northumberland Tyne and Wear NHS Foundation Trust",
  "Dartford and Gravesham NHS Trust",
  "Derbyshire Community Health Services NHS Foundation Trust",
  "Derbyshire Healthcare NHS Foundation Trust",
  "Devon Partnership NHS Trust",
  "Doncaster and Bassetlaw Teaching Hospitals NHS Foundation Trust",
  "Dorset County Hospital NHS Foundation Trust",
  "Dorset Healthcare University NHS Foundation Trust",
  "Dr Gray's Hospital",
  "Dudhope House (Young Persons Unit)",
  "Dudhope Young Peoples Inpatient Unit",
  "Dudley and Walsall Mental Health Partnership NHS Trust",
  "Dumbarton Joint Hospital/Riverview Resource Centre",
  "Dumfries &amp; Galloway Royal Infirmary",
  "Dunaros Community Hospital",
  "Dunbar Hospital",
  "Dundee Dental Hospital",
  "Dundonald Centre",
  "Dykebar Hospital",
  "East Ayrshire Community Hospital",
  "East Cheshire NHS Trust",
  "East Kent Hospitals University NHS Foundation Trust",
  "East Lancashire Hospitals NHS Trust",
  "East London NHS Foundation Trust",
  "East Lothian Community Hospital",
  "East Midlands Ambulance Service NHS Trust",
  "East Of England Ambulance Service NHS Trust",
  "East Suffolk and North Essex NHS Foundation Trust",
  "East Sussex Healthcare NHS Trust",
  "East and North Hertfordshire NHS Trust",
  "Eastwood Resource Centre",
  "Edenhall Hospital",
  "Edinburgh Dental Institute",
  "Edington Cottage Hospital",
  "Ellen's Glen House",
  "Elmwood",
  "Epsom and St Helier University Hospitals NHS Trust",
  "Essex Partnership University NHS Foundation Trust",
  "Eyemouth Day Hospital",
  "Falkirk Community Hospital",
  "Ferryfield House",
  "Festival Business Park",
  "Findlay House",
  "Fleming Cottage Hospital",
  "Florence Street Mental Health Resource Centre",
  "Forth Valley Royal Hospital",
  "Fraserburgh Hospital",
  "Frimley Health NHS Foundation Trust",
  "Galashiels Psychogeriatric Day Hospital",
  "Galavale House",
  "Galloway Community Hospital",
  "Gartnavel General Hospital (Admin Purposes)",
  "Gartnavel Royal Hospital",
  "Gateshead Health NHS Foundation Trust",
  "George Eliot Hospital NHS Trust",
  "Gilbert Bain Hospital",
  "Girvan Community Hospital",
  "Glan Clwyd Hospital",
  "Glasgow Dental Hospital and School",
  "Glasgow Royal Infirmary",
  "Glen O'Dee Hospital",
  "Glencoe Hospital",
  "Glencorse Day Centre",
  "Glenrothes Hospital",
  "Gloucestershire Care Services NHS Trust",
  "Gloucestershire Health and Care NHS Foundation Trust",
  "Gloucestershire Hospitals NHS Foundation Trust",
  "Golden Jubilee National Hospital",
  "Gowanlea",
  "Great Ormond Street Hospital for Children NHS Foundation Trust",
  "Great Western Hospitals NHS Foundation Trust",
  "Greater Manchester Mental Health NHS Foundation Trust",
  "Guy's and St Thomas' NHS Foundation Trust",
  "Hampshire Hospitals NHS Foundation Trust",
  "Harrogate and District NHS Foundation Trust",
  "Hartwoodhill Hospital",
  "Hawick Community  Hospital",
  "Hawick Psychiatric Day Hospital",
  "Hawick Psychogeriatric Day Hospital",
  "Hawkhill Day Hospital",
  "Hay Lodge Hospital",
  "Heather Lodge (CETU)",
  "Herdmanflat Hospital",
  "Hertfordshire Community NHS Trust",
  "Hertfordshire Partnership University NHS Foundation Trust",
  "Homerton University Hospital NHS Foundation Trust",
  "Hounslow and Richmond Community Healthcare NHS Trust",
  "Hull University Teaching Hospitals NHS Trust",
  "Humber Teaching NHS Foundation Trust",
  "Huntlyburn House",
  "Hyperbaric Centre",
  "Ian Charles Community Hospital",
  "Imperial College Healthcare NHS Trust",
  "Inchkeith House",
  "Innistaigh",
  "Insch &amp; District War Memorial Hosp.",
  "Inverclyde Royal Hospital",
  "Inverurie Hospital",
  "Islay Hospital",
  "Isle Of Wight NHS Trust",
  "James Paget University Hospitals NHS Foundation Trust",
  "Jubilee Hospital",
  "Kello Hospital",
  "Kelso Hospital",
  "Kent Community Health NHS Foundation Trust",
  "Kent and Medway NHS and Social Care Partnership Trust",
  "Kettering General Hospital NHS Foundation Trust",
  "Kincardine Community Hospital",
  "King's College Hospital NHS Foundation Trust",
  "Kings Cross Hospital",
  "Kingston Hospital NHS Foundation Trust",
  "Kingsway Care Centre",
  "Kirkcudbright Cottage Hospital",
  "Kirklands Hospital",
  "Kirklandside Hospital",
  "Lady Home Cottage Hospital",
  "Lady Margaret Hospital",
  "Lancashire &amp; South Cumbria NHS Foundation Trust",
  "Lancashire Teaching Hospitals NHS Foundation Trust",
  "Larkfield Unit",
  "Laurel Bank (CETU)",
  "Lawson Memorial Hospital",
  "Leanchoil Hospital",
  "Learning Disabilities Service Healthcare Houses",
  "Leeds Community Healthcare NHS Trust",
  "Leeds Teaching Hospitals NHS Trust",
  "Leeds and York Partnership NHS Foundation Trust",
  "Leverndale Hospital",
  "Lewisham and Greenwich NHS Trust",
  "Liberton Hospital",
  "Lightburn Hospital",
  "Lincolnshire Community Health Services NHS Trust",
  "Lincolnshire Partnership NHS Foundation Trust",
  "Lismore",
  "Little Cairnie Hospital",
  "Liverpool Heart and Chest NHS Foundation Trust",
  "Liverpool University Hospitals NHS Foundation Trust",
  "Liverpool Women's NHS Foundation Trust",
  "Lochmaben Hospital",
  "Lochview Hospital",
  "Lockhart Hospital",
  "London Ambulance Service NHS Trust",
  "London North West University Healthcare NHS Trust",
  "Lorn &amp; Islands Hospital",
  "Luton and Dunstable University Hospital NHS Foundation Trust",
  "Lynebank Hospital",
  "Mackinnon Memorial Hospital",
  "Maidencraig House",
  "Maidstone and Tunbridge Wells NHS Trust",
  "Manchester University NHS Foundation Trust",
  "Maybole Day Hospital",
  "Mearnskirk House",
  "Medway NHS Foundation Trust",
  "Melburn Lodge",
  "Mersey Care NHS Foundation Trust",
  "Mid Cheshire Hospitals NHS Foundation Trust",
  "Mid Essex Hospital Services NHS Trust",
  "Mid Yorkshire Hospitals NHS Trust",
  "Mid-Argyll Community Hospital and Integrated Care Centre",
  "Midlands Partnership NHS Foundation Trust",
  "Midlothian Community Hospital",
  "Midpark Hospital",
  "Migdale Hospital",
  "Milton Keynes University Hospital NHS Foundation Trust",
  "Moffat Community Hospital",
  "Montrose Royal Infirmary",
  "Moorfields Eye Hospital NHS Foundation Trust",
  "Mull and Iona Community Hospital",
  "Murray Royal Hospital",
  "Nairn Town and County Hospital",
  "Netherton",
  "New Craigs Hospital",
  "New Victoria Hospital",
  "Newton Stewart Hospital",
  "Ninewells Hospital",
  "Nithbank Hospital",
  "Nithview Day Hospital",
  "Norfolk Community Health and Care NHS Trust",
  "Norfolk and Norwich University Hospitals NHS Foundation Trust",
  "Norfolk and Suffolk NHS Foundation Trust",
  "North Bristol NHS Trust",
  "North Cumbria Integrated Care NHS Foundation Trust",
  "North Cumbria University Hospitals NHS Trust",
  "North East Ambulance Service NHS Foundation Trust",
  "North East London NHS Foundation Trust",
  "North Middlesex University Hospital NHS Trust",
  "North Staffordshire Combined Healthcare NHS Trust",
  "North Tees and Hartlepool NHS Foundation Trust",
  "North West Ambulance Service NHS Trust",
  "North West Anglia NHS Foundation Trust",
  "North West Boroughs Healthcare NHS Foundation Trust",
  "Northampton General Hospital NHS Trust",
  "Northamptonshire Healthcare NHS Foundation Trust",
  "Northern Devon Healthcare NHS Trust",
  "Northern Lincolnshire and Goole NHS Foundation Trust",
  "Northumbria Healthcare NHS Foundation Trust",
  "Nottingham University Hospitals NHS Trust",
  "Nottinghamshire Healthcare NHS Foundation Trust",
  "Orchard View",
  "Osprey House",
  "Oxford Health NHS Foundation Trust",
  "Oxford University Hospitals NHS Foundation Trust",
  "Oxleas NHS Foundation Trust",
  "Parkview Resource Centre",
  "Peebles Psychogeriatric Day Hospital",
  "Pennine Acute Hospitals NHS Trust",
  "Pennine Care NHS Foundation Trust",
  "Perth Dental Hospital",
  "Perth Royal Infirmary",
  "Peterhead Community Hospital",
  "Pineview",
  "Pitlochry Community Hospital",
  "Pluscarden Clinic",
  "Poole Hospital NHS Foundation Trust",
  "Portree Hospital",
  "Portsmouth Hospitals NHS Trust",
  "Princess Alexandra Eye Pavilion",
  "Project Nightingale NHS Trust",
  "Public Health Wales",
  "Queen Elizabeth University Hospital",
  "Queen Margaret Hospital",
  "Queen Victoria Hospital NHS Foundation Trust",
  "RNI Community Hospital",
  "Raigmore Hospital",
  "Randolph Wemyss Memorial Hospital",
  "Red Deer Centre/Day Hospital",
  "Rehabilitation Hospital",
  "Riverside Resource Centre",
  "Roadmeetings Hospital",
  "Robert Jones and Agnes Hunt Orthopaedic and District Hospital NHS Trust",
  "Roodlands General Hospital",
  "Ross Memorial Hospital",
  "Rotherham Doncaster and South Humber NHS Foundation Trust",
  "Rowanbank Clinic",
  "Roxburghe House",
  "Royal Aberdeen Children's Hospital",
  "Royal Alexandra Hospital",
  "Royal Berkshire NHS Foundation Trust",
  "Royal Brompton and Harefield NHS Foundation Trust",
  "Royal Cornhill Hospital",
  "Royal Cornwall Hospitals NHS Trust",
  "Royal Devon and Exeter NHS Foundation Trust",
  "Royal Edinburgh Hospital",
  "Royal Free London NHS Foundation Trust",
  "Royal Hospital for Children and Young People",
  "Royal Hospital for Children",
  "Royal Hospital for Sick Children (Edinburgh)",
  "Royal Infirmary of Edinburgh at Little France",
  "Royal National Orthopaedic Hospital NHS Trust",
  "Royal Papworth Hospital NHS Foundation Trust",
  "Royal Surrey NHS Foundation Trust",
  "Royal United Hospitals Bath NHS Foundation Trust",
  "Royal Victoria Day Hospital",
  "Royal Victoria Hospital",
  "Salford Royal NHS Foundation Trust",
  "Salisbury NHS Foundation Trust",
  "Sandwell and West Birmingham Hospitals NHS Trust",
  "Scone Day Hospital",
  "Seafield Hospital",
  "Shawmill Resource Centre",
  "Shawpark Resource Centre",
  "Sheffield Children's NHS Foundation Trust",
  "Sheffield Health and Social Care NHS Foundation Trust",
  "Sheffield Teaching Hospitals NHS Foundation Trust",
  "Sherwood Forest Hospitals NHS Foundation Trust",
  "Shrewsbury and Telford Hospital NHS Trust",
  "Shropshire Community Health NHS Trust",
  "Skye House",
  "Solent NHS Trust",
  "Somerset Partnership NHS Foundation Trust",
  "South Central Ambulance Service NHS Foundation Trust",
  "South East Coast Ambulance Service NHS Foundation Trust",
  "South London and Maudsley NHS Foundation Trust",
  "South Tees Hospitals NHS Foundation Trust",
  "South Tyneside And Sunderland NHS Foundation Trust",
  "South Tyneside NHS Foundation Trust",
  "South Warwickshire NHS Foundation Trust",
  "South West London and St George's Mental Health NHS Trust",
  "South West Yorkshire Partnership NHS Foundation Trust",
  "South Western Ambulance Service NHS Foundation Trust",
  "Southend University Hospital NHS Foundation Trust",
  "Southern Health NHS Foundation Trust",
  "Southport and Ormskirk Hospital NHS Trust",
  "Springpark Resource Centre/Day Hosp",
  "St Andrews Community Hospital",
  "St Brendans Cot Hospital",
  "St George's University Hospitals NHS Foundation Trust",
  "St Helens and Knowsley Hospitals NHS Trust",
  "St John's Hospital",
  "St Margaret's Hospital",
  "St Michael's Hospital",
  "St Vincent's Hospital",
  "State Hospital",
  "Stephen Cottage Hospital",
  "Stirling Community Hospital",
  "Stobhill Hospital",
  "Stockport NHS Foundation Trust",
  "Stonehouse Hospital",
  "Stracathro Hospital",
  "Strathclyde Hospital",
  "Stratheden Hospital",
  "Strathmartine Hospital",
  "Surrey and Borders Partnership NHS Foundation Trust",
  "Surrey and Sussex Healthcare NHS Trust",
  "Sussex Community NHS Foundation Trust",
  "Sussex Partnership NHS Foundation Trust",
  "Tameside Hospital NHS Foundation Trust",
  "Taunton and Somerset NHS Foundation Trust",
  "Tavistock and Portman NHS Foundation Trust",
  "Tees, Esk and Wear Valleys NHS Foundation Trust",
  "The Baird Family Hospital",
  "The Balfour",
  "The Christie NHS Foundation Trust",
  "The Clatterbridge Cancer Centre NHS Foundation Trust",
  "The Dudley Group NHS Foundation Trust",
  "The Hillingdon Hospitals NHS Foundation Trust",
  "The Islay Centre",
  "The Knoll Hospital",
  "The Mary Aitkenhead Centre",
  "The Newcastle Upon Tyne Hospitals NHS Foundation Trust",
  "The Orchards",
  "The Princess Alexandra Hospital NHS Trust",
  "The Princess Royal Maternity Unit",
  "The Queen Elizabeth Hospital, King's Lynn. NHS Foundation Trust",
  "The Rotherham NHS Foundation Trust",
  "The Royal Bournemouth and Christchurch Hospitals NHS Foundation Trust",
  "The Royal Marsden NHS Foundation Trust",
  "The Royal Orthopaedic Hospital NHS Foundation Trust",
  "The Royal Wolverhampton NHS Trust",
  "The Walton Centre NHS Foundation Trust",
  "Thomas Hope Hospital",
  "Thornhill Hospital",
  "Three Towns Resource Centre",
  "Tippethill Hospital",
  "Torbay and South Devon NHS Foundation Trust",
  "Treastaigh",
  "Turner Memorial Hospital",
  "Turriff Cottage Hospital",
  "Udston Hospital",
  "Ugie Hospital",
  "Uist &amp; Barra Hospital",
  "United Lincolnshire Hospitals NHS Trust",
  "University College London Hospitals NHS Foundation Trust",
  "University Hospital Ayr",
  "University Hospital Crosshouse",
  "University Hospital Hairmyres",
  "University Hospital Monklands",
  "University Hospital Southampton NHS Foundation Trust",
  "University Hospital Wishaw",
  "University Hospital of Derby and Burton NHS Foundation Trust",
  "University Hospitals Birmingham NHS Foundation Trust",
  "University Hospitals Bristol NHS Foundation Trust",
  "University Hospitals Coventry and Warwickshire NHS Trust",
  "University Hospitals Of Leicester NHS Trust",
  "University Hospitals Of Morecambe Bay NHS Foundation Trust",
  "University Hospitals Plymouth NHS Trust",
  "University Hospitals of North Midlands",
  "Vale of Leven General Hospital",
  "Velindre NHS Trust",
  "Victoria Hospital Annexe",
  "Victoria Hospital",
  "Victoria Maternity Unit",
  "Victoria Memorial Cottage Hospital",
  "Walsall Healthcare NHS Trust",
  "Warrington and Halton Hospitals NHS Foundation Trust",
  "Warrix Avenue Mental Health Community Rehabilitation Unit",
  "Wellgreen Cottages",
  "Welsh Ambulance Services NHS Trust;",
  "West Glasgow",
  "West Hertfordshire Hospitals NHS Trust",
  "West London NHS Trust",
  "West Midlands Ambulance Service University NHS Foundation Trust",
  "West Suffolk NHS Foundation Trust",
  "Wester Moffat Hospital",
  "Western General Hospital",
  "Western Isles Hospital",
  "Western Sussex Hospitals NHS Foundation Trust",
  "Weston Area Health NHS Trust",
  "Weston Day Hospital",
  "Whitburn Day Hospital",
  "Whitburn House",
  "Whitehills Health and Community Care Centre",
  "Whitehills Hospital",
  "Whittington Health NHS Trust",
  "Whytemans Brae Hospital",
  "Wick Town and County Hospital",
  "William Fraser Centre",
  "Wirral Community NHS Foundation Trust",
  "Wirral University Teaching Hospital NHS Foundation Trust",
  "Woodend General Hospital",
  "Woodland View",
  "Woodlands Resource Centre",
  "Woolmanhill Hospital",
  "Worcestershire Acute Hospitals NHS Trust",
  "Worcestershire Health and Care NHS Trust",
  "Wrexham Maelor Hospital",
  "Wrightington, Wigan and Leigh NHS Foundation Trust",
  "Wye Valley NHS Trust",
  "Yeovil District Hospital NHS Foundation Trust",
  "York Teaching Hospital NHS Foundation Trust",
  "Yorkshire Ambulance Service NHS Trust",
  "Ysbyty Gwynedd Hospital",
];

export default clinics
