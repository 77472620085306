import { Box} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import './index.css'
function IEFooter() {
    return (
      <Box className="footer-container" >
        <Box className="navigations" >
          <a href='https://www.freestyle.abbott/ie-en/home.html'>DISCOVER</a>
          <a href='https://www.freestyle.abbott/ie-en/products.html'>PRODUCTS</a>
          <a href='https://www.freestyle.abbott/ie-en/getting-started.html'>GETTING STARTED</a>
          <a href='https://www.freestyle.abbott/ie-en/myfreestyle/secure/myfreestyle.html'>MYFREESTYLE</a>
          <a href='https://www.freestyle.abbott/ie-en/support.html'>SUPPORT</a>
        </Box>
        <div className='horizontal-line'></div>
        <div className='logo-footer'>
          <img src={process.env.PUBLIC_URL +'/abbott-white-logo.png'} alt='abbott logo'/>
        </div>
        <Box className="other-navigations" >
          <a href='https://www.freestyle.abbott/ie-en/legal/privacy-policy.html'>Privacy Policy</a>
          <a href='https://www.freestyle.abbott/ie-en/legal/terms-and-conditions.html'>Terms and Conditions of Use</a>
          <a href='https://www.freestyle.abbott/ie-en/legal/terms-of-sale.html'>Terms of Sale</a>
          <a href='https://www.freestyle.abbott/ie-en/legal/cookie-policy.html'>Cookie Policy</a>
            <div id="teconsent"></div>
        </Box>
        <div className='footer-text'>
         <span>
         The sensor housing, FreeStyle, Libre, and related brand marks are marks of Abbott. Other trademarks are the property of their respective owners. No use of any Abbott trademark, trade name, or trade dress in this site may be made without the prior written authorisation of Abbott Laboratories, except to identify the product or services of the company.
         </span>
         <span>
         This website and the information contained herein is intended for use by residents of the United Kingdom. The product images are for illustrative purposes only.
         </span>
         <span>
         ©️ 2024 Abbott Laboratories Limited. All rights reserved.
         <br />
         Registered Number: 11542. Registered Office: Block B, Liffey Valley Office Campus, Quarryvale, Dublin 22 D22 X0Y3, Ireland
         </span>
        </div>
      </Box>
    );
  }
  
  export default IEFooter;
