import React, { useState } from 'react';
import { TextField, Box, Autocomplete } from '@mui/material';
import Button from '@mui/material/Button';
import NormalField from "./normal-field";
import isIEDomain from '../../helpers/domainChecker'
const AddressLookup = ({ register, errors, setValue }) => {
	const apiUrl = process.env.REACT_APP_POSTCODE;
	const [options, setOptions] = useState([]);
	const [postCode, setPostCode] = useState('');
	const [street, setStreet] = useState('');
	const [city, setCity] = useState('');
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [alreadySelected, setAlreadySelected] = useState(false);
	
	const handleLookUp = (e) => {
		setAlreadySelected(false);
		fetchData(postCode)
	}

	const fetchData = async (code) => {
		if(!code) return;
		try {
			const response = await fetch(apiUrl+code, {
				method: 'GET',
				headers: {
					'x-api-key': process.env.REACT_APP_API_KEY
				},
			});
			const data = await response.json();
			console.log(data);
			const optionsData = data.payload.suggestions.map(item => item.address)
			console.log(optionsData)
			setOptions(optionsData);
			setDropdownOpen(true)
			// if(data.payload?.suggestions[0]){
			// 	const address = data.payload?.suggestions[0].address;
			// 	console.log(address)
			// 	const street = extractStreet(address)
			// 	const city = extractCity(address)
			// 	setValue('street',street)
			// 	setValue('city',city)
			// }

		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};
	function extractStreet(address) {
		const street = address.split(',')[0].trim();
		return street;
	}
	function extractCity(address) {
		const city = address.split(',')[1].trim();
		return city;
	}
	const handlePostcodeChange = (e) => {
		setValue('postCode',e.target.value)
		setPostCode(e.target.value)
	};
	const handleStreetChange = (e) => {
		setValue('street',e.target.value)
		setStreet(e.target.value)
	};
	const handleCityChange = (e) => {
		setValue('city',e.target.value)
		setCity(e.target.value)
	};
	const postCodeSelected = (e,val) => {
		setAlreadySelected(true);
		if(val){
			setDropdownOpen(false)
			const s = extractStreet(val)
			const c = extractCity(val)
			setValue('street',s)
			setValue('city',c)
			setStreet(s)
			setCity(c)
		}
	};
	// useEffect(()=>{
	// 	fetchData(postCode);
	// },[postCode])

	return (
		<Box className="form-group">
			<Box className="form-group horizontal-form mobile-row" sx={{ marginTop: '24px', alignItems: 'center' }}>
				<NormalField
					label={isIEDomain?"EIR code *":"Postcode *"}
					placeholder={isIEDomain?"EIR code":"Postcode"}
					{...register('postCode')}
					value={postCode}
					errors={errors.postCode}
					onChange={handlePostcodeChange}
				/>
				{!isIEDomain &&
				<Button className="rounded-btn text-white" variant="contained" color="darkBlue" sx={{paddingX:"32px",paddingY:"12px"}} onClick={handleLookUp} >Find address</Button>
				}
			</Box>
			{ options.length>0 && !alreadySelected && <Autocomplete
				disablePortal
				id="postcode-field"
				options={options}
				sx={{ width: 300 }}
				open={dropdownOpen}
				onChange={postCodeSelected}
				renderInput={(params) =>
					<TextField {...params}
					           InputLabelProps={{ shrink: true, sx: { top: '-18px', left: '-13px' }, disableAnimation: true }}
					           sx={{ width: '100%', '& legend': { display: 'none' } }}
					           label="Address *"
					           placeholder="Select Address" />}
			/>}
			<Box className="form-group horizontal-form" >
				<NormalField
					label="Street name *"
					placeholder="Enter street name"
					{...register('street')}
					value={street}
					errors={errors.street}
					onChange={handleStreetChange}
				/>
				<NormalField
					label="Town or City *"
					placeholder="Enter town or city"
					{...register('city')}
					value={city}
					errors={errors.city}
					onChange={handleCityChange}
				/>
			</Box>
		</Box>
	);
};

export default AddressLookup;
