import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import isIEDomain from './helpers/domainChecker'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

var script = document.createElement('SCRIPT');
var domainUrl = '' + document.location;
var cookieKey = isIEDomain ? process.env.REACT_APP_COOKIE_IE_KEY : process.env.REACT_APP_COOKIE_UK_KEY;
script.src = 'https://consent.trustarc.com/v2/notice/' + cookieKey;
document.getElementsByTagName('head')[0].appendChild(script);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
